/***** WELCOME SCREEN CONTAINER STYLES *****/
.welcome-screen-container {
  display: flex;
  flex-wrap: wrap;
}
.welcome-screen-container > div {
  width: 50%;
}

/***** WELCOME BOX STYLES *****/
.welcome-box {
  background-color: #fff;
  height: 280px;
  width: 96%;
  margin: 8px auto;
  border: 1px solid $divider;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  justify-content: space-between;
}
.welcome-box img {
  width: 200px;
}
//.welcome-box:first-child {margin-left: 0; margin-right: 16px;}
//.welcome-box:last-child {margin-right: 0; margin-left: 16px;}
.welcome-box-large {
  margin: 0 auto;
  max-width: 100%;
}
.welcome-checkbox {
  text-align: center;
  padding-top: 20px;
}
.welcome-feature-name {
  text-align: center;
}
.feature-title {
  color: $accent-color;
  font-size: 18px;
  font-weight: 600;
}
.feature-explanation {
  color: $secondary-text;
  min-height: 40px;
  font-size: 13px;
}

@media only screen and (max-width: 768px) {
  .welcome-box-container.form-inline-third {
    width: 31.3%;
    margin-right: 3%;
    display: inline-block;
  }
  .welcome-box-container.form-inline-third:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 650px) {
  .welcome-box-container.form-inline-third {
    width: 100%;
  }
  .welcome-box {
    max-width: none;
    margin-left: 0 !important;
  }
}

/***** WELCOME MODAL STYLES *****/
.welcome-modal .rrm-body {
  display: flex;
  flex-direction: column;
  position: relative;
}
.welcome-modal img {
  max-width: 100%;
  border-radius: 3px 3px 0 0;
}
.welcome-modal-footer {
  padding: 24px;
  background: $primary-color;
  color: #fff;
  border-radius: 0 0 3px 3px;
  position: relative;
}
.welcome-modal-title {
  font-size: 20px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.demo-text {
  margin-bottom: 32px;
}
.dont-show {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 8px;
  margin-bottom: 0;
}
.dont-show:hover {
  color: $accent-color-3;
  transition: all 300ms linear;
  cursor: pointer;
}
.welcome-modal-close {
  position: absolute;
  border: 0;
  top: 6px;
  right: 4px;
  font-size: 16px;
  color: $accent-color-3;
}
.welcome-modal-close:hover {
  color: $primary-color;
  transition: all 300ms linear;
  cursor: pointer;
}
