.share-modal-toggle {
  .lbl {
    position: relative;
    display: block;
    height: 16px;
    width: 38px;
    background: #898989;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 6px;
    margin-right: 8px;
    &:after {
      position: absolute;
      left: 1px;
      top: 1px;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 100px;
      background: $background-dark;
      box-shadow: 0px 3px 3px rgba(#000, 0.05);
      content: "";
      transition: all 0.3s ease;
    }
    &:active {
      &:after {
        transform: scale(1.15, 0.85);
      }
    }
  }

  .cbx:checked ~ label {
    background: lighten($accent-color, 35%);
    &:after {
      left: 21px;
      background: $accent-color;
    }
  }

  .press {
    margin-bottom: 40px;
  }

  .hidden {
    display: none;
  }
}
