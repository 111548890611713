/***** GENERAL IDEAS PAGE STYLES *****/
.ideas-page-container {
  display: block !important;
}
.ideas-page {
  overflow-x: scroll;
  text-align: center;
}
.scrollable-container {
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - 90px);
  display: flex;
  margin: 0 20px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    width: 10%;
  }
  &::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }
}

/***** ZIVUG ZONE *****/
#idea-resumes {
  position: fixed;
  left: 0;
  width: 350px;
  z-index: 1000;
}

#idea-save-button {
  margin-top: 22px;
}

@media only screen and (max-width: 720px) {
  #idea-resumes {
    position: unset;
    //margin-top: 84px;
    ///padding-top: 0 !important;
    width: 100%;
    z-index: 2;
  }
}

/***** COLOR KEY STYLES *****/
.color-key {
  margin: -20px 0 10px 20px;
  padding-left: 20px;
}
.color-key p {
  font-size: 10px;
  display: inline-block;
  vertical-align: text-top;
  margin: 0 10px 0 0;
  text-transform: uppercase;
  padding-top: 2px;
}
.key-square {
  height: 12px;
  width: 12px;
  margin: 0 8px;
  position: relative;
  display: inline-block;
}
.suggested-square {
  background-color: $suggested;
}
.researching-square {
  background-color: $researching;
}
.busy-square {
  background-color: $busy;
}
.said-yes-square {
  background-color: $said-yes;
}
.said-no-square {
  background-color: $said-no;
}
.waiting-square {
  background-color: $waiting;
}

/***** ADD IDEA HEADER BUTTON STYLES *****/
.add-idea-btn {
  color: $accent-color;
  font-size: 11px;
  font-weight: 600;
  transition: all 300ms linear;
  padding: 5px 30px !important;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  right: 32px;
  top: 125px;
  background: $background-dark;
  margin-bottom: 30px;
  .fa {
    margin-right: 5px;
  }
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }
}

/***** IDEAS COLUMN STYLES *****/
.ideas-column-container {
  display: inline-block;
  margin: auto;
  padding: 0 10px;
  min-width: 300px;
  max-width: 450px;
  width: 33%;
}
.ideas-column {
  background-color: $background-dark;
  height: calc(100vh - 75px);
  margin-bottom: 25px;
  padding: 10px 10px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid $divider;
  display: inline-block;
  width: 100%;
  vertical-align: text-top;
}
.idea-items-scrollbar {
  padding-right: 15px;
}
.ideas-column-title {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  margin-left: 16px;
  color: #f03b74;
  span {
    color: #ffffff;
    display: inline-block;
    margin-left: 6px;
    margin-top: -1px;
    font-size: 10px;
    font-weight: 600;
    min-width: 15px;
    height: 15px;
    background: $accent-color-3;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
  }
}

/***** IDEA CARD STYLES *****/
.idea-items-group {
  margin-bottom: 16px;
  transition: all 0.2s linear;
  &:hover {
    .idea-card {
      background: $background-medium;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .suggested {
    border-left: 5px solid $suggested;
  }
  .researching {
    border-left: 5px solid $researching;
  }
  .busy {
    border-left: 5px solid $busy;
  }
  .said-yes {
    border-left: 5px solid $said-yes;
  }
  .said-no {
    border-left: 5px solid $said-no;
  }
  .waiting {
    border-left: 5px solid $waiting;
  }
}
.ideas-card-row {
  // border-left: 5px solid $background-medium;
  background: #ffffff;
  border-radius: 3px;
}
.idea-card-info-dropdown {
  height: 35px;
}
.idea-card {
  border-bottom: 1px solid $divider;
  padding: 10px;
  text-align: left;
  min-height: 60px;
  .idea-card-image {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: top;
    margin-right: 14px;
  }
  .idea-card-info {
    display: inline-block;
    width: calc(100% - 54px);
    .idea-card-title {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: $accent-color-3;
      padding-top: 3px;
    }
    .idea-card-details {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
    }
    .idea-card-details span {
      margin-right: 10px;
      font-size: 12px;
      font-family: "Open Sans";
      color: $secondary-text;
    }
    .idea-card-details .created-action {
      font-size: 12px;
      font-family: "Open Sans";
      color: #ffffff;
      width: 78px;
      text-align: center;
      height: 18px;
      border-radius: 5px;
    }
    .idea-card-details .created-action-active {
      background-color: $primary-color;
    }
    .idea-card-details .created-action-inactive {
      background-color: $waiting;
      cursor: none !important;
    }
    .idea-card-details .created-action-accepted {
      background-color: $said-yes;
    }
    .idea-card-details .created-action-rejected {
      background-color: $said-no;
    }
  }
}
.ideas-card.placeholder,
.placeholder:hover {
  height: 126px;
  margin-bottom: 16px;
  background-color: rgba(208, 208, 208, 1);
}

/***** MEDIA QUERIES *****/
@media only screen and (min-width: 1800px) {
  .ideas-column-container {
    width: 450px;
  }
}

@media only screen and (min-width: 1600px) {
  .ideas-card-left-col {
    width: 25%;
  }
  .scrollable-container {
    text-align: center;
  }
  .ideas-column-container {
    text-align: left;
  }
}

@media only screen and (max-width: 1200px) {
  .ideas-column-container:first-of-type {
    padding-left: 0;
  }
  .color-key {
    padding-left: 0;
  }
}

@media only screen and (max-width: 720px) {
  .ideas-page-container {
    padding-top: 175px !important;
  }
  .header-right {
    display: none;
  }
  .color-key {
    margin: -24px 0 8px 6px !important;
  }
  .scrollable-container {
    height: calc(100vh - 190px) !important;
    margin: 0 4px;
  }
  .ideas-column-container {
    padding: 4px;
  }
  .ideas-column {
    height: calc(100vh - 215px);
    padding: 4px;
  }
  .ideas-column .btn-flat {
    margin-top: -15px;
  }
  .add-idea-btn {
    top: 146px;
    right: 24px;
  }
}
