#content-main-admin {
  position: fixed;
  left: 0;
  right: 350px;
  background: $background-medium;
  height: 100%;
}
#content-main-admin-right {
  position: fixed;
  left: 350px;
  right: 0;
  background: $background-medium;
  height: 100%;
}
#content-main-admin.sidebar-hidden {
  right: 0;
}
//.admin-container #sidebar-content.active {border-left: 0;}
//.sidebar-content-content-admin {background-color: $background-dark !important; border-left: none !important;}
#sidebar-content-admin {
  position: fixed;
  right: 0;
  width: 346px;
  background: $background-dark;
  border-left: 1px solid $divider;
  transition: width 400ms linear;
  height: 100vh;
  z-index: 2;
}
.sidebar-content-arrow-admin {
  padding: 17px 10px;
  display: block;
  text-align: center;
  background: $accent-color;
  color: #ffffff;
  position: absolute;
  top: 101px;
  left: -32px;
  transition: all 300ms linear;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
}
.sidebar-content-arrow-admin:hover {
  background: $primary-color-dark;
  color: #ffffff;
}
#sidebar-content-content-admin {
  background: $background-medium;
  position: absolute;
  z-index: 5;
  top: 60px;
  right: 0;
  border-left: 1px solid $divider;
}
.admin-subheader-padding-top {
  padding-top: 25px;
}
.admin-content-padding {
  padding: 0 3%;
}
.content-padding-margin {
  margin-top: 20px;
}

.main-width {
  width: 95%;
  margin: 0 2.5%;
}

#header.header-admin {
  //* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2196f3+0,f03b74+100 */
  background: #2196f3; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #2196f3 0%,
    #f03b74 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #2196f3 0%,
    #f03b74 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #2196f3 0%,
    #f03b74 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2196f3', endColorstr='#f03b74',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#header.header-admin .header-menu-main li a {
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  padding: 23px 0 15px 0 !important;
  margin: 0 12px !important;
}
#header.header-admin .header-menu-main li a.active {
  border-bottom: 2px solid #fff;
}

@media only screen and (max-width: 1240px) {
  #header.header-admin .header-menu-main li:last-child {
    padding: 0 !important;
  }
}

/***** ADMIN PAGE SUBHEADER STYLES *****/
#admin-page-subheader {
  padding: 0 2.9%;
  margin-top: 30px;
  border-radius: 3px;
}
.admin-page-subheader {
  background: #fff;
  padding: 16px 15px;
}
.admin-page-title {
  font-size: 22px;
  color: $accent-color-3;
  font-weight: 600;
  margin-bottom: 0;
}
.admin-page-title .fa {
  margin-right: 8px;
}
.admin-page-subtitle {
  color: $secondary-text;
  font-size: 12px;
  margin-left: 15px;
  font-weight: 600;
}

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .admin-page-subtitle {
    display: block;
    margin-left: 28px;
  }
}
.admin-page-subtitle span.count {
  color: $primary-color;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 600;
}
.admin-search {
  padding-top: 24px;
  padding-left: 16px;
  color: $secondary-text;
}
.admin-filters {
  @extend .admin-search;
}
.admin-filter {
  display: inline-block;
  font-size: 12px;
  margin-right: 16px;
}

/***** ADMIN PAGE TABLE STYLES *****/
.admin-page-table {
  width: 100%;

  thead {
    background-color: $background-dark-medium;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
  }
  tbody {
    background-color: #fff;
    min-height: 400px;
  }
  tbody tr {
    height: 64px;
    line-height: 64px;
  }
  td {
    padding: 0 16px;
  }
}

/***** ADMIN COLUMN STYLES *****/
.admin-col-small {
  width: 15%;
  float: left;
}
.admin-col-med {
  width: 25%;
  float: left;
}
.admin-col-lg {
  width: 35%;
  float: left;
}
.admin-col-xl {
  width: 40%;
  float: left;
}

/***** ADMIN SIDEBAR STYLES *****/
#sidebar-content-content-admin .panel-actions {
  border-bottom: none;
}
.admin-sidebar-resume-header {
  margin: 20px 0 35px 0;
  text-align: center;
  padding-top: 32px;

  .resume-header-image {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    margin: 0 auto;
  }
  .resume-header-info {
    margin-bottom: 25px;

    .resume-name {
      color: $primary-color;
      margin-bottom: 10px;
      margin-top: 12px;
    }
    .resume-header-details p {
      display: inline-block;
      margin: 0 10px;
    }
    .resume-header-details .resume-header-detail-title {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      color: $secondary-text;
    }
  }
}
.admin-sidebar-panel {
  border-radius: 3px;
  background-color: $background-light;
  padding: 24px;
}
.admin-sidebar-panel h3 {
  color: $primary-color;
  margin-top: 0;
  margin-bottom: 8px;
}
.admin-sidebar-panel p {
  color: $secondary-text;
  font-size: 13px;
  font-weight: 600;
}

//ask shana about these styles
.admin-sidebar-left .header-right {
  display: none;
}
//#content-main.column-layout-two {right: 0;}
//#content-main.column-layout-three {left: 0; right: 0;}

.sidebar-alert {
  color: #fff;
  position: relative;
}
.sidebar-alert.sidebar-alert-flagged {
  background: $accent-color !important;
}
.sidebar-alert.sidebar-alert-flagged:before {
  content: "\f024";
  position: absolute;
  top: 5px;
  right: 10px;
  font-family: FontAwesome;
  opacity: 0.5;
}
.sidebar-alert.sidebar-alert-engaged {
  background: $accent-color-2 !important;
  transition: background 300ms linear;
}
.sidebar-alert.sidebar-alert-engaged:hover {
  background: $accent-color !important;
  cursor: pointer;
}
.sidebar-alert.sidebar-alert-engaged:before {
  content: "\f219";
  position: absolute;
  top: 5px;
  right: 10px;
  font-family: FontAwesome;
  opacity: 0.5;
}
.sidebar-alert .sidebar-label {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.sidebar-alert p {
  margin-bottom: 0;
  font-size: 12px;
}

.header-title {
  display: inline-block;
  width: 20%;
  margin-right: 3%;
  vertical-align: middle;
}
.single-merge .header-title {
  width: 100%;
}
.header-search {
  display: inline-block;
  width: 55%;
  margin-right: 3%;
}
.header-filters {
  display: inline-block;
  width: 19%;
  vertical-align: middle;
}
.header-filters span {
  margin-right: 15px;
}
.header-filters-include {
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  vertical-align: middle;
}
.header-filters-include span {
  margin-left: 5px;
  display: inline-block;
}

.sidebar-content-buttons-btn {
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 15px;
}
.sidebar-content-buttons-btn:first-child {
  margin-left: 0;
}
.btn-flat.btn-section {
  padding: 5px;
  font-size: 11px;
  background: $primary-color;
  color: #fff;
  transition: all 300ms linear;
}
.btn-flat.btn-section a {
  color: #fff;
  text-decoration: none;
}
.btn-flat.btn-section:hover {
  background: $accent-color;
  cursor: pointer;
}
.btn-flat.btn-section.btn-resume {
  margin-left: 5px;
}

.btn-nonbutton {
  margin-right: 5px;
  padding: 0 0 2px 0;
  border-bottom: 1px solid $accent-color-3;
  border-radius: 0;
  transition: all 300ms linear;
}
.btn-nonbutton:hover {
  color: $accent-color;
  cursor: pointer;
}

.message-text {
  font-size: 11px;
  color: $accent-color;
  font-weight: 600;
  margin-left: 10px;
}

.sidebar-content-section-info {
  margin-bottom: 16px;
}
.sidebar-content-section-info ul {
  padding-left: 17px;
  list-style: square;
}
.sidebar-content-section-info-title {
  font-weight: 600;
  margin-right: 3px;
  color: $accent-color-3;
  display: block;
  margin-bottom: 2px;
}
.sidebar-content-section-title-sub {
  border-bottom: 1px solid $divider;
  padding-bottom: 5px;
  font-weight: 600;
  margin: 30px 0 15px 0;
  color: $accent-color-3;
  display: block;
}
.sidebar-content-section-title-sub:first-child {
  margin-top: 0;
}
.sidebar-content-section-title-sub:first-child.sidebar-content-section-title-sub-only {
  margin-top: 25px;
}
.section-linebreak {
  display: block;
}
.section-linebreak-small {
  margin-bottom: 2px;
}

.sidebar-content-section .sidebar-content-section-title {
  font-size: 18px;
  text-transform: uppercase;
  border-bottom: 1px solid $divider;
  display: block;
  margin-bottom: 18px;
  margin-top: 5px;
}

.idea-items-group-container {
  border-bottom: 1px solid $divider;
  border-radius: 3px;
  background: $background-dark-medium;
  padding: 15px;
  margin-bottom: 24px;
}

.idea-items-group-info {
  overflow: auto;
  margin-bottom: 8px;
}
.admin-idea-state {
  font-weight: 600;
  color: $primary-color;
  display: block;
}
.admin-idea-date {
  color: $accent-color-3;
  font-size: 11px;
}

.selected-resume {
  padding-top: 22px;
}

.sidebar-list-item-info-inboxResume.sidebar-admin-resume-list.sidebar-list-item-full:hover {
  background: $background-dark-medium;
  cursor: default;
}
.sidebar-list-item-info-inboxResume.sidebar-admin-resume-list.sidebar-list-item-full:hover
  h5 {
  color: $secondary-text !important;
}

.sidebar-admin-resume-list.sidebar-list-item-info-inboxResume h5 {
  margin: 10px 5% 0 5%;
  width: 55%;
  color: $accent-color-3;
}
.sidebar-list-item-info-inboxResume.sidebar-list-item-info-inboxResumeView:hover
  h5,
.sidebar-list-item-info-inboxResume.sidebar-list-item-info-inboxResumeView:hover
  .sidebar-stats
  span {
  color: #fff;
}
.sidebar-admin-resume-list.sidebar-list-item-info-inboxResume.sidebar-list-item-full
  h5 {
  width: 90%;
}
.sidebar-admin-resume-list .sidebar-stats {
  margin-left: 5%;
  margin-bottom: 5px;
  font-size: 11px;
}
.sidebar-admin-resume-list .sidebar-stats .sidebar-stat {
  display: inline-block;
  color: $accent-color-3;
}
.sidebar-admin-resume-list .sidebar-stats .sidebar-stat .fa {
  font-size: 12px;
  vertical-align: middle;
  margin-right: 15px;
}
.sidebar-admin-resume-list .sidebar-stats .sidebar-stat.sidebar-stat-pinned {
  color: $accent-color-2;
}
.sidebar-stat-pinned {
  width: 15%;
  margin-right: 5%;
}
.sidebar-stat-date {
  width: 45%;
  text-align: right;
  margin-right: 5%;
}
.sidebar-stat-views {
  width: 25%;
  margin-right: 5%;
}
.sidebar-list-item-btn-view {
  margin-right: 0 !important;
}
.sidebar-list-item-btn-view .fa {
  margin-left: 5px;
}

.sidebar-admin-resume-list .sidebar-stats .sidebar-stat .fa {
  margin-left: 0;
}
.sidebar-list-item-info-inboxResume {
  padding: 5px 0;
  margin-bottom: 15px;
  border-radius: 3px;
  background: #e8e8e8;

  h5 {
    display: inline-block;
    margin: 10px 5% 10px 5%;
    width: 47%;
    color: $accent-color-3;
  }
  p {
    display: inline-block;
    text-align: right;
    width: 30%;
    margin-right: 5%;
    font-size: 12px;
  }
}

.panel-action-four .panel-action.panel-action-fullWidth {
  width: 100% !important;
}
.btn-sizeperpage {
  background: $primary-color;
  padding: 10px 0;
  width: 44px;
  text-align: center;
  color: #fff;
  transition: all 300ms linear;
  cursor: pointer;
  display: inline-block;
}
.btn-sizeperpage:hover,
.btn-sizeperpage.active {
  background: $accent-color;
}
.btn-sizeperpage:first-child {
  border-radius: 3px 0 0 3px;
  border-right: 1px solid $primary-color-dark;
}
.btn-sizeperpage:last-child {
  border-radius: 0 3px 3px 0;
  border-left: 1px solid $primary-color-dark;
}

.engage-modal .engage-modal-content {
  padding: 2%;
}
.engage-modal-content form .autosuggest-list {
  padding-left: 0;
  padding-right: 0;
}
.engagement-details {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 16px;
  height: 384px;
}

.form-label-outside {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 11px;
  letter-spacing: 1px;
  color: #000000;
  margin-top: 16px;
}

/***** CHANI RESUME TYPES STYLES *****/
.sidebar-content-content-admin
  .inbox-resume-section
  td:first-child.resume-table-title {
  min-width: 195px !important;
}
.sidebar-content-content-admin .type-amount .fa-circle {
  margin-right: 3px;
  color: $background-dark-medium;
  transition: all 300ms linear;
}
.sidebar-content-content-admin .filled {
  color: $accent-color !important;
}
.sidebar-content-content-admin .inbox-resume-section td:first-child {
  min-width: 170px;
  color: #727272;
  font-size: 12px;
  vertical-align: top;
  font-weight: 600;
  display: block;
  margin-bottom: 0;
}

/***** SINGLE MERGE STYLES *****/
.merge-back-arrow {
  margin-right: 8px;
  cursor: pointer;
}
.merge-back-arrow:hover {
  transition: all 300ms linear;
  color: $primary-color;
}
.duplicates {
  position: relative !important;
  padding-top: 88px !important;
  padding-bottom: 88px;
}
.duplicate-status {
  text-align: center;
  color: #727272;
}
.duplicate-resume {
  padding: 0 20px 20px 20px !important;
}
.duplicate-resume .resume-fullName {
  display: inline-block !important;
}
.duplicate-resume .inbox-resume-section {
  margin-top: 0;
  padding: 0 20px;
  border-bottom: none;
}
.duplicate-resume .inbox-resume-section tbody tr {
  display: table-row;
  line-height: 24px;
}

/***** SINGLE MERGE FOOTER STYLES *****/
.single-merge-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: $background-dark-medium;

  .navigation {
    background: $accent-color-3;
    padding: 6px 0;
  }
  .navigation .btn-sidebar-create {
    display: inline-block;
    color: #fff;
  }
  //.navigation .btn-sidebar-create:hover {color: #fff;}
  .navigation-buttons {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  p {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 3px;
    margin: 0 16px;
    cursor: pointer;
  }
  p:first-of-type:hover {
    color: #333;
  }
  p:last-of-type:hover {
    color: #333;
  }
}
.resume-arrows {
  width: 288px;
  height: 24px;
  margin: 0 auto;
  position: relative;
}
.resume-arrows .previous-resume {
  position: absolute;
  left: 0;
}
.resume-arrows .next-resume {
  position: absolute;
  right: 0;
}

/***** MOBILE STYLES *****/
@media only screen and (max-width: 720px) {
  .header-title {
    width: 100%;
    margin-bottom: 16px;
  }
  .header-search {
    width: 100%;
    margin-bottom: 16px;
  }
  .header-filters {
    width: 100%;
  }
  .btn-sizeperpage {
    padding: 8px;
    width: auto;
    font-size: 12px;
  }
  .pagination > li > a,
  .pagination > li > span {
    font-size: 12px;
    width: auto !important;
    height: auto !important;
    padding: 3px 8px !important;
  }
}
