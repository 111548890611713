#support {
  position: relative;
  z-index: 100000;
}
//.support-content-arrow {
//  width: 19px;
//  height: 18px;
//  margin-top: 3px;
//  color: #1976d2;
//  background-color: #fff;
//  border-radius: 3px;
//  font-size: 13px;
//}

.support-content-arrow.support-open {
  display: none;
}
//.support-content-arrow.support-closed {top: 5px; right: 0px;}

.support-container {
  width: 390px;
  position: fixed;
  top: 0;
  right: 0;
  border-left: 1px solid $divider;
  box-shadow: -8px -5px 5px -6px $divider;
  z-index: 100000;
  background: #fff;
}

.support-container.support-closed {
  display: none;
}

.support-bar {
  background: $primary-color;
  color: #fff;
  padding: 16px 25px;
  overflow: auto;

  .support-bar-title {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 18px;
  }
  .support-button {
    margin-bottom: 0;
    transition: all 300ms linear;
    cursor: pointer;
  }
  .support-bar-back {
    float: left;
    font-size: 18px;
  }
  .support-bar-back:hover {
    color: $accent-color;
  }
  .support-bar-close {
    float: right;
    font-size: 20px;
  }
  .support-bar-close:hover {
    transform: rotate(90deg);
  }
}

.support-bar-back {
  font-size: 12px;
  padding: 20px 25px 0 25px;
  transition: all 300ms linear;
  display: block !important;
  text-align: left;
  margin-bottom: 0;
}
.support-bar-back:hover {
  color: $accent-color;
  cursor: pointer;
}
.support-bar-back span {
  margin-right: 5px;
}

.support-search {
  background: $background-medium;
  padding: 25px;
  border-top: 1px solid $divider;
  border-bottom: 1px solid $divider;

  input {
    width: 80%;
    display: inline-block;
    border-radius: 3px 0 0 3px;
    height: 42px;
  }
  button {
    width: 20%;
    display: inline-block;
    font-size: 13px;
  }
  .btn-support-search {
    border-radius: 0 3px 3px 0;
    padding: 0;
    height: 41.5px;
    border: 0;
    background: $primary-color;
    color: #fff;
    text-align: center;
    transition: all 300ms linear;
    vertical-align: top;
  }
  .btn-support-search:hover {
    background: $accent-color;
    cursor: pointer;
  }
}

.support-header {
  background: $background-medium;
  padding: 25px;

  .support-header-icon {
    width: 29%;
    margin: 1.5%;
    display: inline-block;
    text-align: center;
    color: $accent-color-3;
    font-size: 15px;
    cursor: pointer;

    &:hover .fa {
      background: $primary-color;
      color: #fff;
    }

    .fa {
      background: #fff;
      border-bottom: 1px solid $divider;
      padding: 20px;
      border-radius: 3px;
      font-size: 18px;
      margin-bottom: 5px;
      transition: all 300ms linear;
    }
    .fa:hover {
      transform: scale(1.1);
    }

    a {
      display: block;
      color: $accent-color-3;
      font-size: 13px;
      transition: all 300ms linear;
      text-decoration: none;
    }
    //a:hover {text-decoration: none; color: $primary-color;}

    p {
      display: block !important;
      font-size: 13px;
    }
  }
  .support-header-icon-active .fa {
    background: $accent-color;
    color: #fff;
  }
}

.support-header + iframe {
  border: none;
  width: 100%;
}

.support-content {
  background: #fff;
  padding: 25px;
  text-align: left;

  h2 {
    font-size: 15px;
    margin-bottom: 0;
  }

  .support-category {
    font-weight: 600;
    border-bottom: 3px solid $divider;
    padding-bottom: 5px;
    margin-bottom: 5px;
    display: block;
    text-transform: uppercase;
    color: $primary-color;
  }
  .post-title {
    font-size: 20px;
    margin-top: 0;
    line-height: 32px;
    color: $primary-color;
  }

  ul {
    list-style-type: none;
    line-height: 24px;
    padding-left: 16px;
  }
}
.support-content-section,
.support-header-hide {
  display: none;
}
.support-content-section.support-content-section-current {
  display: block;
}
#support-content-section-chat {
  position: relative;
  height: 480px;
}

.support-content-section ul {
  list-style-type: none;
  padding-left: 0px;
}
.support-content-section ul li {
  font-weight: 600;
}
.support-content-section .support-content-articles > li {
  margin-bottom: 25px;
}

.support-content-section ul ul {
  list-style-type: none;
  padding-left: 16px;
  padding-top: 8px;
}
.support-content-section ul ul li {
  font-weight: 400;
  line-height: 24px;
  color: $secondary-text;
  cursor: pointer;
}
.support-content-section ul ul li:hover {
  text-decoration: underline;
}

.support-content-section .support-content-articles ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
.support-content-section .support-content-articles ul li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

.support-content-article-list {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  padding-top: 8px;
  color: $secondary-text;
}
.support-content-article-list li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

.support-content-article-list li:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: all 300ms linear;
}

.support-content-container > div > div:nth-child(3) {
  display: none;
}
