.react-bs-table-container {
  background: #fff;
  border-radius: 3px;
  padding: 0 15px 15px 15px;
  margin-bottom: 100px;
  position: relative;
}
.react-bs-table {
  border: 0;
  margin: 0;
}
.react-bs-table table {
  border-spacing: 0;
}
.react-bs-table thead {
  border-radius: 3px 3px 0 0;
}
.react-bs-table thead th {
  font-size: 11px;
  font-weight: 600;
  padding: 16px 15px !important;
}
.react-bs-table tbody tr {
  border-bottom: none;
  transition: all 300ms linear;
}
.react-bs-table .table-bordered > tbody > tr > td {
  padding: 8px 16px;
}
.react-bs-table .table-bordered > tbody > tr > td.default-focus-cell {
  outline: none !important;
}
.react-bs-table-container .react-bs-table-search-form {
  margin-bottom: 16px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background: $background-medium;
}
.react-bs-table-pagination .btn-group {
  float: left;
}
.react-bs-table-pagination .btn-default {
  width: 100px;
  border-radius: 3px;
}
.table-hover > tbody > tr:hover {
  background: $background-dark;
  cursor: pointer;
}
.react-bs-container-body {
  margin-top: 57px;
}

//.react-bs-table-pagination {background: #fff; position: fixed; bottom: 0; margin: 0; padding: 1%; width: 70%; border-bottom: 1px solid $divider; border-radius: 3px;}
.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.react-bs-container-header .table {
  position: fixed;
  width: 71%;
  background: #fff;
}
.not-fixed .react-bs-container-header .table {
  position: relative;
  width: 100% !important;
}
.not-fixed .react-bs-container-body {
  margin-top: 8px;
}
.react-bs-table-tool-bar {
  padding: 16px 16px 0 16px;
}
.react-bs-table-csv-btn:focus {
  background: $accent-color !important;
}

@media only screen and (max-width: 1850px) {
  .react-bs-container-header .table {
    width: 70.5%;
  }
}
@media only screen and (max-width: 1800px) {
  .react-bs-container-header .table {
    width: 70%;
  }
}
@media only screen and (max-width: 1750px) {
  .react-bs-container-header .table {
    width: 69.5%;
  }
}
@media only screen and (max-width: 1700px) {
  .react-bs-container-header .table {
    width: 69%;
  }
}
@media only screen and (max-width: 1650px) {
  .react-bs-container-header .table {
    width: 68.5%;
  }
}
@media only screen and (max-width: 1600px) {
  .react-bs-container-header .table {
    width: 68%;
  }
}
@media only screen and (max-width: 1550px) {
  .react-bs-container-header .table {
    width: 67%;
  }
}
@media only screen and (max-width: 1500px) {
  .react-bs-container-header .table {
    width: 66%;
  }
}
@media only screen and (max-width: 1450px) {
  .react-bs-container-header .table {
    width: 65%;
  }
}
@media only screen and (max-width: 1400px) {
  .react-bs-container-header .table {
    width: 64%;
  }
}
@media only screen and (max-width: 1350px) {
  .react-bs-container-header .table {
    width: 63.5%;
  }
}
@media only screen and (max-width: 1300px) {
  .react-bs-container-header .table {
    width: 62%;
  }
}
@media only screen and (max-width: 1250px) {
  .react-bs-container-header .table {
    position: static;
    width: 100% !important;
  }
  .react-bs-container-body {
    margin-top: 0;
  }
}

.sidebar-hidden .react-bs-container-header .table {
  width: 87.5%;
}
@media only screen and (max-width: 1500px) {
  .sidebar-hidden .react-bs-container-header .table {
    width: 87%;
  }
}
@media only screen and (max-width: 1150px) {
  .sidebar-hidden .react-bs-container-header .table {
    width: 86.5%;
  }
}
@media only screen and (max-width: 950px) {
  .sidebar-hidden .react-bs-container-header .table {
    width: 86%;
  }
}

.logged-never {
  color: $primary-color;
  font-weight: 600;
}
.logged-recent {
  font-weight: 600;
  color: $accent-color;
}
.flag {
  color: $accent-color;
}
.engaged {
  color: $accent-color-2;
}
.count-high {
  color: $accent-color;
  font-weight: 600;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 0;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: $primary-color;
  border-color: $primary-color;
}

/***** CHANI SORT ARROWS *****/
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
