.sub-header {
  background: $primary-color-dark;
  position: fixed;
  width: 100%;
  z-index: 1001;
  padding-top: 110px;

  .sidebar-left,
  .content-main,
  .sidebar-content {
    top: 60px;
    text-align: center;
    height: auto;

    h2 {
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      padding: 16px 8px;
      font-weight: 400;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  .sub-header .content-main h2 {
    padding: 0 8px 8px 8px;
  }
}

.sub-header-resume {
  padding-top: 60px;
  .resume-messageBar {
    min-height: 50px;
  }
  .resume-messageBar-content {
    text-align: right;
    min-height: 60px;
  }
  .resume-messageBar p {
    line-height: 50px;
    margin-bottom: 0;
    margin-right: 25px;
    font-size: 12px;
    font-weight: 600;
  }
  .btn-secondary {
    margin-top: 7px;
    float: right;
  }
}

.sub-header-content {
  padding-top: 150px;
}

@media only screen and (max-width: 720px) {
  .sub-header {
    padding-top: 84px !important;
    //padding-bottom: 8px;
    .sidebar-left {
      display: none;
    }
    .content-main {
      top: 0 !important;
    }
    .sidebar-content {
      display: none;
    }
  }
  .sub-header-content {
    padding-top: 170px;
  }
}
