html,
body,
#app,
#app .container-app {
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0 0 10px;
}

a {
  text-decoration: none;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

.overflow-auto {
  overflow: auto;
}

.margin-bottom {
  margin-bottom: 35px;
}

.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.inline {
  display: inline-block;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.clear {
  clear: both;
}

.no-resize {
  resize: none;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.link {
  cursor: pointer;
}

.singleColumn-page {
  background: $background-light;
  margin-top: 15%;
}

.panel {
  margin-bottom: 18px;
  border-bottom: 1px solid #e1e1e1;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 15px;
}

.panel.panel-withPadding {
  padding: 5%;
}

/***** PADDING *****/
.header-padding {
  padding-top: 60px;
}
.header-padding-subheader {
  padding-top: 150px;
}
.content-padding {
  padding: 0 20px;
  overflow: auto;
}

@media only screen and (max-width: 720px) {
  .header-padding {
    padding-top: 115px;
  }
  .header-padding-subheader {
    padding-top: 144px !important;
  }
}

/***** COLORS *****/
.primary-color {
  color: $primary-color;
}

/***** SCROLLBAR STYLES *****/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c4c4c4;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.mui-select__menu > div.border-bottom {
  border-bottom: 1px solid $divider;
  padding: 0;
  margin: 0;
  height: auto;
}

/***** HEADER TEXT STYLES *****/
h2 {
  font-weight: bold;
  font-size: 28px;
  margin: 15px 0 20px 0;
}
h3 {
  font-size: 13px;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 15px;
}
h4 {
  padding-bottom: 10px;
}

/***** MESSAGE PLACEHOLDERS *****/
.message-empty {
  text-align: center;
  top: 35%;
  position: relative;
  i {
    font-size: 100px;
    color: $accent-color;
  }
  p {
    font-size: 12px;
    color: #9d9d9d;
    font-weight: 600;
    padding: 0 20%;
  }
  p:first-of-type {
    font-size: 15px;
    color: $secondary-text;
  }
}

@media only screen and (min-width: 1200px) {
  .message-empty p {
    padding: 0 40%;
  }
}

.message-sidebar-empty {
  text-align: center;

  .message-sidebar-empty-icon {
    font-size: 50px;
    color: $secondary-text;
    margin-bottom: 10px;
    margin-top: 55px;
  }
  .message-sidebar-empty-icon-inSection {
    margin-top: 25px;
  }
  p {
    font-size: 14px;
    color: $secondary-text;
    margin-bottom: 25px;
  }
  span {
    display: block;
    font-weight: 600;
  }
}

.message-placeholder {
  color: $secondary-text;
}

.message-circle-empty {
  text-align: center;

  .message-circle-empty-icon {
    font-size: 50px;
    color: $background-dark;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #ffffff;
    padding-top: 25px;
    display: inline-block;
  }
  p {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  span {
    display: inline-block;
    font-weight: 600;
  }
}

.message-circle-empty.message-circle-empty-dark {
  padding-top: 35px;

  .message-circle-empty-icon.message-circle-empty-icon-small {
    color: #ffffff;
    background: $background-dark;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding-top: 16px;
    font-size: 18px;
  }
  p {
    color: $secondary-text;
  }
}

/***** MIDDLE PANEL - ACTIONS *****/
.panel-actions {
  color: $secondary-text;
  border-bottom: 1px solid $divider;
  background: $background-medium;
  padding: 20px 20px 18px 20px;

  i {
    margin-right: 5px;
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0;
  }

  .panel-action {
    display: inline-block;
    margin-right: 24px;
    font-weight: 600;
    transition: all 0.2s linear;
    box-sizing: border-box;
  }
  .panel-action span {
    margin-right: 6px;
  }
  .panel-action:last-child {
    margin-right: 0;
  }
  .panel-action:hover {
    color: $primary-color;
    cursor: pointer;
  }

  .panel-action-button {
    padding: 5px 7px;
    border-radius: 3px;
    transition: all 300ms linear;
  }

  .panel-action-pending {
    color: $accent-color-2;
  }
  .panel-action-pending:hover {
    cursor: auto;
  }
  .panel-action-active {
    color: $accent-color-2;
  }
  .panel-action-active:hover {
    color: $accent-color-2;
    cursor: pointer;
  }

  .panel-action-inline {
    display: inline-block;
    margin-right: 24px;
  }
}

@media only screen and (max-width: 550px) {
  .panel-actions {
    .panel-action {
      margin-right: 16px;
    }
    .panel-action-inline {
      margin-right: 16px;
    }
    .all-resumes p {
      display: none !important;
    }
  }
}

.panel-actions-fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.groupPage .panel-actions-fixed {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 23px;
}

.sidebar-hidden .panel-actions-fixed {
  width: 85%;
}
.content-padding-fixed-top {
  margin-top: 68px;
}

.panel-actions.panel-action-fullwidth {
  padding: 0;
  background: #ffffff;

  .panel-action {
    width: 33%;
    border-right: 1px solid $divider;
    text-align: center;
    padding: 23px 2% 24px 2%;
    margin: 0;
  }
  .panel-action:last-child {
    border: 0;
    width: 34%;
  }
  .panel-action.active {
    background: $background-medium;
  }
  .panel-action:hover {
    background: $primary-color !important;
    color: #ffffff;
  }
}

.panel-action-four {
  .panel-action {
    width: 25% !important;
  }
  .panel-action:last-child {
    border: 0;
    width: 25% !important;
  }
}

#sidebar-content-content .panel-actions.panel-action-fullwidth {
  border-bottom: 1px solid #f5f5f5;
}
#sidebar-content-content.sidebar-content-content-admin {
  background: none;
}

.panel-actions.panel-action-one {
  padding: 0;
  .panel-action {
    width: 100%;
    text-align: center;
    padding: 24px 2%;
    margin: 0;
  }
}

//.panel-actions.panel-action-fullwidth .panel-action {background: #e8e8e8;}

.panel-actions.panel-actions-additional {
  background: none;

  .panel-action {
    background: $background-medium;
    margin: 0;
    padding: 10px 20px;
  }
  .panel-action:first-child {
    border-radius: 3px 0 0 3px;
    border-right: 1px solid $divider;
  }
  .panel-action:last-child {
    border-radius: 0 3px 3px 0;
    border-left: 1px solid $divider;
  }
}

.section {
  background: #ffffff;
  border-bottom: 1px solid $divider;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 10px;

  h3 {
    color: $primary-color;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .section-infoTitle {
    min-width: 150px;
    display: inline-block;
    color: $secondary-text;
    font-weight: 600;
    font-size: 12px;
  }
  .long-section-infoTitle {
    float: left;
  }
  .group-address {
    float: left;
    max-width: 75%;
    margin-bottom: 5px;
  }

  .content-main-section {
    margin-bottom: 25px;
    overflow: auto;

    p {
      margin-bottom: 5px;
      clear: both;
    }
    p.group-description {
      margin-bottom: 20px;
    }
  }

  table {
    width: 100%;
  }
}

.dimmer #sidebar-left .sidebar-list,
.dimmer #content-main,
.dimmer #sidebar-content {
  opacity: 0.2;
}

.ReactVirtualized__Table__headerTruncatedText {
  font-size: 12px;
  text-transform: capitalize;
  color: $secondary-text;
}
.ReactVirtualized__Table__row {
  font-size: 13px;
  border-bottom: 1px solid $divider;
}

.list-items-container {
  text-align: center;
}
.list-items {
  border-bottom: 1px solid $divider;
  margin-bottom: 18px;
  padding-bottom: 20px;
}
.list-items:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.list-item {
  display: block;
  width: 100%;
  text-align: left;
}
.list-item p {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 12px;
}
.list-item span {
  font-weight: 400;
  color: $secondary-text;
}
.list-item .sent {
  margin-top: 10px;
  display: inline-block;
  color: $primary-color;
}
.list-item .fa {
  color: $primary-color;
}
.list-item-info {
  margin-bottom: 10px;
}
.list-item-secondary {
  margin: 0 0 10px 0;
  color: $secondary-text;
  font-size: 11px;
  font-weight: normal;
}

.circle {
  color: #ffffff;
  display: inline-block;
  margin-left: 6px;
  font-size: 10px;
  font-weight: 600;
  width: 18px;
  height: 18px;
  background: $accent-color;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 9px;
  text-align: center;
  padding-top: 2px;
}

.dropdown-icon {
  display: none;
}
.dropdown-icon-active {
  display: block;
}

.icon {
  color: $secondary-text;
  vertical-align: middle;
  margin-bottom: 10px;
  cursor: pointer;
  p {
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom;
  }
}

.action-pending {
  color: $accent-color;
  font-size: 11px;
  vertical-align: baseline;
}

.footer-singleForm {
  padding-top: 0;
  margin-bottom: 55px;
  .btn-flat {
    float: right;
    width: 31.3%;
    display: inline-block;
    margin-bottom: 15px;
    min-height: 40px;
  }
  .settings-message {
    font-size: 12px;
    color: $primary-color;
    clear: both;
    text-align: right;
    font-weight: 600;
  }
}

.footer-singleForm .validation-error-general {
  clear: both;
  margin-top: -8px !important;
}

@media only screen and (max-width: 991px) {
  .footer-singleForm .btn-flat {
    width: 100%;
  }
}

.group-modal-footer-btns {
  float: right;
  margin-top: 20px;
  .cancel-btn {
    background-color: $divider;
    color: #fff;
  }
  .create-btn {
    background-color: $primary-color;
    color: #fff;
  }
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid:focus {
  outline: none;
}
button[disabled] {
  cursor: not-allowed;
}

/***** CHANIS MODAL STYLES *****/
.modal-no-results {
  font-size: 18px;
  text-align: center;
}

.headerText-with-icon {
  display: inline-block;
  margin-right: 5px;
  color: $accent-color-3;
}
.headerText-with-icon-icon {
  color: $accent-color-3;
}

.text-link-under-input {
  color: $accent-color;
  font-size: 12px;
  padding: 10px 5px 0 0;
}

/***** CHANI MOBILE MEDIA QUERIES *****/
@media only screen and (min-width: 720px) and (max-width: 840px) {
  .resumePage,
  .groupPage {
    .resume-header {
      text-align: center;
    }
    .resume-header .resume-header-info {
      width: 100%;
    }
    .resume-header-image {
      margin: 0 auto !important;
      display: block !important;
    }
    .resume-fullName {
      display: block;
    }
    .resume-name-container,
    .resume-header-details {
      padding-left: 0 !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .mobile-hide {
    display: none;
  }
  .sidebar-hidden .panel-actions-fixed {
    width: 100%;
  }
  #sidebar-content {
    position: absolute;
    z-index: 1001;
    right: 1px;
    top: 98px;
    height: calc(100vh - 98px) !important;
    max-height: 100vh;
  }
  #sidebar-content.active {
    padding-top: 0;
  }
  .sidebar-content-arrow {
    top: 36px;
    z-index: 1001;
  }
  #sidebar-content-content {
    background-color: $background-medium;
    z-index: 5;
    top: 0;
    right: 0;
    display: none;
    max-height: 100%;
    height: 100%;
  }
  #sidebar-content.active #sidebar-content-content {
    display: block;
  }
  #sidebar-content-content .panel-action > div {
    height: calc(100vh - 166px) !important;
  }
  .panel-actions.panel-action-fullwidth .panel-action {
    padding: 24px 2% 17px 2%;
  }
  .resumes-panel-actions-fixed {
    padding: 24px 20px 15px 20px;
  }
}

@media only screen and (min-width: 720px) {
  .all-resumes {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .resumes-panel-actions-fixed .panel-action p {
    display: none;
  }
  .all-resumes p {
    display: inline-block !important;
  }
  .count p {
    display: block !important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .resumes-panel-actions-fixed .panel-action p {
    display: none;
  }
  .all-resumes p {
    display: inline-block !important;
  }
  .count p {
    display: block !important;
  }
}

@media only screen and (max-width: 720px) {
  .panel-actions-fixed .panel-action p {
    display: none;
  }
  .all-resumes p {
    display: inline-block !important;
  }
  .count p {
    display: block !important;
  }
  .resumePage,
  .groupPage {
    .resume-header {
      text-align: center;
    }
    .resume-header .resume-header-info {
      width: 100%;
    }
    .resume-header-image {
      margin: 0 auto !important;
      display: block !important;
    }
    .resume-fullName {
      display: block;
    }
  }
}

@media only screen and (min-width: 721px) and (max-width: 875px) {
  .groupPage {
    .panel-actions-fixed .panel-action p {
      display: none;
    }
    .all-resumes p {
      display: inline-block !important;
    }
  }
}

@media only screen and (max-width: 640px) {
  .groupPage {
    .panel-actions-fixed .panel-action p {
      display: none;
    }
    .all-resumes p {
      display: inline-block !important;
    }
  }
}

.panel-action-pinned-active {
  color: $accent-color-2;
}
.panel-action-engaged-active {
  color: $accent-color;
}
.panel-action-flagged-active {
  color: $primary-color;
}

.account-settings-fields {
  margin-bottom: 25px;
  overflow: auto;
}
.account-settings-action {
  margin-top: 10px;
  display: inline-block;
  font-size: 12px;
  margin-left: 15px;
  font-weight: 600;
  color: $primary-color;
}
.account-settings-action:first-child {
  margin-left: 0;
}
.btn-account-settings-action {
  color: $accent-color-3;
  transition: all 300ms linear;
  font-weight: 600;
}
.btn-account-settings-action:hover {
  color: $accent-color;
  cursor: pointer;
}

.sweet-alert h2 {
  font-size: 18px;
  color: $secondary-text;
}

.verification-image {
  width: 32%;
  height: auto;
  margin: 15px 15px 0 0;
}
.helper-text {
  font-size: 11px;
  font-style: italic;
}
.helper-text.helper-text-break {
  display: block;
}

.powered-by {
  a {
    font-size: 12px;
    color: $primary-color-dark;
    text-decoration: none;
    display: block;
    text-align: center;
    transition: all 300ms linear;
    padding: 15px 0;
  }
  a:hover {
    color: #fff;
  }
  a:hover i {
    color: $accent-color;
    animation: pulse 2s infinite;
    transform-origin: center center;
  }
  i {
    margin: 5px;
  }
  img {
    width: 75px;
    height: auto;
    margin-left: 5px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.resume-fullName {
  font-size: 16px;
  color: $accent-color-3;
  vertical-align: middle;
  font-weight: 400;
  margin-left: 10px;
}

.title {
  font-size: 15px;
  font-weight: 600;
  display: block;
  line-height: 24px;
  text-transform: uppercase !important;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 5px;
  margin-bottom: 15px !important;
}

.bold-text {
  font-weight: 600;
}
.bold {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.margin-top {
  margin-top: 20px;
}

.inline {
  display: inline-block;
}

.validation-error-near-button {
  float: right;
  padding: 10px 15px;
}

.panelContainer {
  padding: 0 20px 20px 20px !important;
  border-radius: 3px;
  border-bottom: 1px solid $divider;
  margin-bottom: 16px;
  overflow: auto;
}

//styles from the resume and group page
.resume-section {
  background: #ffffff;
  padding: 20px 0;
  position: relative;
  font-size: 13px;

  h3 {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  tr {
    border-bottom: none;
  }

  tr td:first-child {
    min-width: 170px;
    color: $secondary-text;
    font-size: 12px;
    vertical-align: top;
    font-weight: 600;
  }

  td {
    padding: 4px 0;
  }

  p {
    line-height: 22px;
  }

  textarea {
    overflow: auto;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  .resume-table-title {
    color: $accent-color-3;
    font-size: 12px;
    vertical-align: top;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 720px) {
  .resume-section {
    padding-bottom: 30px !important;
  }
}

.resume-header {
  margin: 20px 0 15px 0;
  cursor: default !important;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid $divider;

  .resume-engaged {
    background: $background-dark-medium;
    color: $secondary-text;
    padding: 5px 10px;
    border-radius: 3px;
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 600;

    i {
      margin-right: 10px;
      color: $accent-color-2;
    }
  }

  .resume-header-image {
    display: inline-block;
    margin-right: 15px;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    overflow: hidden;
    object-fit: cover;
  }
  .resume-header-image img {
    width: 100%;
    height: auto;
  }
  .resume-header-info {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 115px);
    .resume-name-container,
    .resume-header-details {
      padding-left: 10px;
    }
    .resume-name {
      color: $accent-color-5;
      margin-bottom: 4px;
      margin-top: 6px;
      background: transparent;
    }
    .resume-fullName {
      white-space: nowrap;
    }
    .resume-name::-webkit-input-placeholder {
      font-weight: 600;
    }
    .resume-info-details {
      display: inline-block;
      color: $accent-color-3;
      background: $background-dark-medium;
      border-radius: 3px;
      margin-left: 10px;
      padding: 0 5px;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 17px;
      vertical-align: middle;
    }
    .resume-header-details p {
      display: inline-block;
      margin: 0 20px 0 0;
    }

    .resume-header-details .resume-header-detail-title {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      color: $secondary-text;
      min-width: 40px;
    }
  }
  .resume-id {
    position: absolute;
    color: $secondary-text;
    bottom: 5px;
    right: 10px;
    text-align: right;
    span {
      font-size: 10px;
      vertical-align: super;
    }
  }
}

/***** VALIDATION ERROR STYLES *****/

.validation-error.validation-error-general {
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  display: block;
  background: red;
  padding: 5px 10px;
  border-radius: 3px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 15px;
}

.validation-error-general {
  font-weight: 600;
  color: red;
  font-size: 14px;
}
.validation-error-general .fa {
  margin-right: 5px;
}

@media only screen and (max-width: 400px) {
  .by-button-iframe.by-button-iframe-right-center {
    display: none;
  }
}

/***** HEADER SECTION STYLES *****/
.header-section {
  background: #ffffff;
  padding: 20px 0;
  position: relative;
  font-size: 13px;
}

.notifications-bar {
  background: $accent-color-2;
  border-radius: 3px;
  padding: 10px;
  margin: 0 2%;
}
.notifications-bar p {
  color: #fff;
  margin: 0;
}
