.registration-page {
  display: flex;
  justify-content: center;
  .parallax {
    width: 100vw;
    background: url(/images/register-background.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .registration-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .page-account-logo {
      width: 250px;
      padding: 36px 0 24px 0;
    }
  }
  .registration-content {
    max-width: 700px;
    background-color: #fff;
    padding: 24px 7%;
  }
  .account-terms {
    font-size: 10px;
    color: #727272;
    padding-top: 15px;
    text-align: center;
    .account-terms-link {
      color: #727272;
    }
  }
  .login-link {
    color: $accent-color;
    display: block;
    text-align: center;
    padding-bottom: 26px;
  }
}

.register-page {
  @extend .page-login;
}

.register-box {
  @extend .page-account-container;
  width: 750px;
  height: auto;
  overflow: auto;
}

.register-left-side {
  background-color: #f8f8f8;
  height: 100%;
  border-radius: 3px 0 0 3px;
  border-right: 1px solid $divider;
  .has-error {
    font-size: 12px;
    padding-left: 35px;
  }
}

.phone {
  float: left;
}

.login-here {
  @extend .new-account;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.register-radios .rw-widget-input {
  box-shadow: none;
}
.register-radios .rw-widget-input ul li {
  padding-left: 0;
}
