.loader-bar-container {
  position: absolute;
  top: 25%;
  left: 47%;
}

.loader-bar,
.loader-bar:before,
.loader-bar:after {
  background: $primary-color;
  border-radius: 3px;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 0.5em;
  height: 1em;
}

.loader-bar {
  color: rgba(255, 255, 255, 0);
  text-indent: -9999em;
  margin: 20px auto;
  position: relative;
  font-size: 20px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-bar:before,
.loader-bar:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader-bar:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bar:after {
  left: 1em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 3em;
  }
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-sizing: border-box;
  border: 2px groove #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0px 0px -50px;
  border-top-color: rgba(33, 150, 243, 1);
  border-left-color: rgba(33, 150, 243, 0.9);
  border-bottom-color: transparent;
  border-right-color: rgba(33, 150, 243, 0.7);
  animation: rotate 1000ms infinite linear;
  transform: translateZ(0px);
}

.loader-admin {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-sizing: border-box;
  border: 2px solid $background-dark;
  position: absolute;
  left: 40%;
  top: 50%;
  margin: -16px 0px 0px -16px;
  border-top-color: #2196f3;
  border-left-color: rgba(33, 150, 243, 0.9);
  border-bottom-color: transparent;
  border-right-color: rgba(33, 150, 243, 0.7);
  animation: rotate 1000ms infinite linear;
  transform: translateZ(0px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-panel {
  .loader {
    width: 30px;
    height: 30px;
    bottom: 20px;
    left: 40%;
    top: auto;
  }
}

.loader-small {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  box-sizing: border-box;
  border: 2px solid $accent-color;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-bottom-color: transparent;
  border-right-color: #ffffff;
  animation: rotate 1000ms infinite linear;
  transform: translateZ(0px);
  margin: 0 auto;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loader-saving {
  padding: 20%;
  text-align: center;
  font-size: 25px;
  color: $accent-color;
  text-transform: lowercase;
}
.loader-saving span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loader-saving span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-saving span:nth-child(3) {
  animation-delay: 0.4s;
}

$zt-blue: #2196f3;
$zt-pink: #ff4081;

.zt-logo {
  position: relative;
  margin: 40px auto;
  width: 150px;
}

.zt-logo-pink {
  position: relative;
  z-index: 100;
  width: 80px;
  height: 100px;
  animation: flickering 3s infinite;
  animation-delay: 4s;
  mix-blend-mode: multiply;
}

.zt-logo-blue {
  @extend .zt-logo-pink;
  margin-left: 30px;
  margin-top: -65px;
}

.zt-dot-pink {
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: $zt-pink;
  border-radius: 3px;
  opacity: 0;
}

.zt-dot-blue {
  @extend .zt-dot-pink;
  background-color: $zt-blue;
}

.zt-dot-a {
  top: 0;
  left: 0;
  animation: animation-dot-a 1.5s ease;
  animation-fill-mode: forwards;
}

.zt-dot-b {
  bottom: 0;
  left: 0;
  animation: animation-dot-b 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.zt-dot-c {
  bottom: 0;
  right: 0;
  animation: animation-dot-c 1.5s ease;
  animation-fill-mode: forwards;
}

.zt-dot-d {
  top: 0;
  right: 0;
  animation: animation-dot-d 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.zt-dot-e {
  top: 0;
  left: 0;
  animation: animation-dot-a 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.zt-dot-f {
  bottom: 0;
  left: 0;
  animation: animation-dot-b 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

.zt-dot-g {
  bottom: 0;
  right: 0;
  animation: animation-dot-c 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.zt-dot-h {
  top: 0;
  right: 0;
  animation: animation-dot-d 1.5s ease;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

@keyframes animation-dot-a {
  0% {
    opacity: 0;
    top: 0;
    bottom: auto;
    height: 12px;
  }
  100% {
    opacity: 1;
    height: 100px;
  }
}

@keyframes animation-dot-b {
  0% {
    opacity: 0;
    left: 0;
    right: auto;
    width: 12px;
  }
  100% {
    opacity: 1;
    width: 80px;
  }
}

@keyframes animation-dot-c {
  0% {
    opacity: 0;
    bottom: 0;
    top: auto;
    height: 12px;
  }
  100% {
    opacity: 1;
    height: 100px;
  }
}

@keyframes animation-dot-d {
  0% {
    opacity: 0;
    right: 0;
    left: auto;
    width: 12px;
  }
  100% {
    opacity: 1;
    width: 80px;
  }
}

@keyframes flickering {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
