.shadchan-table,
.zz-admin-users {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  table {
    width: calc(100vw - 20px);
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  .about {
    max-width: 600px;
  }
  .approve {
    color: $said-yes;
    cursor: pointer;
  }
  .deny {
    color: $said-no;
    cursor: pointer;
  }
  ////////USERS//////////
  .search-container {
    display: flex;
    margin-bottom: 24px;
  }
  .search-input {
    width: 200px;
    height: 30px;
  }
  .search {
    width: 100px;
    height: 30px;
    cursor: pointer;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination {
    padding-top: 16px;
    display: flex;
    justify-content: center;
  }
  .button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
