.mm-resume-modal-actions {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.make-match-button {
  background-color: #f03b74;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
}

#my-matches-search {
  width: 256px;
}

#mm-search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mm-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff8c02;
  width: 66px;
  height: 29px;
  padding: 0 8px;
  border-radius: 3px;
  color: #fff !important;
  cursor: pointer;
}
.mm-filter-active {
  color: $busy !important;
}

#mm-search-filter {
  padding: 0 10px !important;
}
