/***** SIDEBAR CONTENT - STYLES *****/
/*** SIDEBAR - TABS ***/
.sidebar-tab {
  display: none;
}
.sidebar-tab.active {
  display: block;
}

#sidebar-content-content .sidebar-tab {
  margin-top: 20px;
}

/*** SIDEBAR - SECTIONS ***/
.sidebar-content-section {
  border-bottom: 1px dashed $divider;
  margin-bottom: 15px;

  textarea {
    background: none;
    border: 0;
    width: 100%;
    outline: 0;
    color: $secondary-text;
  }
  ::-webkit-input-placeholder {
    color: #999;
  }
  :-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #999;
  }

  .text-autosaving {
    color: $accent-color;
  }
}

.sidebar-resume-shares {
  color: $secondary-text;
  cursor: default !important;
  p {
    margin-bottom: 0;
  }
  .sidebar-resume-shares-number {
    color: $accent-color;
  }
  &:hover {
    cursor: pointer;
  }
}

.sidebar-content-list {
  padding: 0;
  list-style: none;

  li {
    padding: 5px 5px 5px 0;
  }
  li a {
    color: $secondary-text;
    text-decoration: none;
    transition: all 0.2s linear;
  }
  li a:hover {
    color: $primary-color;
  }

  .idea-items-group:hover .idea-card {
    background-color: $background-light;
  }
}
.sidebar-content-list-groups {
  padding: 0;
  color: $secondary-text;
  list-style: none;
}
.sidebar-content-list-groups li {
  background: #fff;
  padding: 0;
  border-radius: 3px;
}
.sidebar-content-list-groups .group-name {
  height: 40px;
  line-height: 40px;
  background: #f5f5f5;
  padding: 0 8px;
  border-radius: 3px 3px 0 0;
}
.sidebar-content-list-groups .group-name span {
  font-size: 11px;
  background: #fff;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 8px;
}
.sidebar-content-list-groups .info {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.sidebar-content-list-groups .info h2 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}
.sidebar-content-list-groups .info p {
  margin-bottom: 2px;
  font-size: 13px;
}
.sidebar-content-list-groups .info span {
  font-size: 12px;
  font-weight: 400;
}
.sidebar-content-list-groups .info .headerText-with-icon-icon {
  float: right;
  font-size: 16px;
}
.sidebar-content-list-groups .note {
  padding: 8px;
}
.sidebar-content-list-groups .note .headerText-with-icon {
  float: left;
  font-size: 13px;
}
.sidebar-content-list-groups .note .fa-lock {
  margin-left: 8px;
}
.sidebar-content-list-groups .note .fa-pencil {
  float: right;
}
.sidebar-content-list-groups .note textarea {
  padding: 0;
  font-size: 12px;
}
.sidebar-content-list-groups .note textarea:disabled {
  resize: none;
  box-shadow: none;
  cursor: default;
  min-height: initial;
  opacity: 1;
}
.sidebar-content-list-groups .note-actions {
  text-align: right;
}
.sidebar-content-list-groups .note-actions span {
  padding-left: 8px;
}

/*** SIDEBAR - MESSAGES - LIST VIEW ***/
.sidebar-conversations .sidebar-list-item-info {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s linear;
  background: $background-dark-medium;
  border-radius: 3px;
}
.sidebar-conversations .sidebar-list-item-info:hover {
  background: $background-dark;
  border-radius: 3px;
}

.sidebar-conversations .sidebar-list-item-title,
.sidebar-conversations .sidebar-list-item-extra,
.sidebar-conversations .sidebar-list-item-info-details,
.sidebar-conversations .sidebar-list-item-btn-view {
  display: inline-block;
}

.sidebar-conversations .sidebar-list-item-title {
  margin-bottom: 0;
  width: 58%;
  margin-right: 2%;
}
.sidebar-conversations .sidebar-list-item-extra {
  font-size: 12px;
  color: $primary-color;
  width: 40%;
  text-align: right;
}
.sidebar-conversations .sidebar-list-item-info-details {
  color: $secondary-text;
  font-size: 11px;
  width: 40%;
  margin-right: 2%;
}
.sidebar-conversations .sidebar-list-item-btn-view {
  width: 58%;
  text-align: right;
  color: $secondary-text;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
}

/*** SIDEBAR - MESSAGES - MESSAGE VIEW ***/
.sidebar-conversations .content-padding {
  padding: 0;
}
.sidebar-conversations .inbox-header {
  padding-top: 32px;
}
.sidebar-conversations .inbox-header .inbox-header-suggestion {
  margin: 0;
}
.sidebar-conversations .inbox-header .inbox-header-resumes {
  text-align: left;
}
.sidebar-conversations .inbox-header .inbox-header-resumes .btn-secondary {
  text-align: left !important;
}
.sidebar-conversations .inbox-header .inbox-header-resumes div {
  float: none;
}
.sidebar-conversations .inbox-header .btn-flat p {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}
.sidebar-conversations .inbox-messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 10px 0 0;
  margin-bottom: 25px;
  margin-right: -10px;
  max-width: 100%;
}
.sidebar-conversations .inbox-messages .inbox-message {
  margin-bottom: 10px;
}
.sidebar-conversations .mui-col-md-6 {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.sidebar-conversations .mui-col-md-6 p {
  padding-left: 25px;
}
.sidebar-conversations .mui-col-md-4 {
  width: 100%;
  margin: 0;
  padding: 0 20px 0 15px;
}
.sidebar-conversations .mui-col-md-4 .btn-light {
  background: none;
  display: inline-block;
  width: 50%;
}

/***** SIDEBAR - INBOX - RESUMES *****/
.sidebar-list-item-info-inboxResume {
  cursor: pointer;
  transition: all 300ms linear;
  padding: 5px 0;
  margin-bottom: 15px;
  border-radius: 3px;
  background: $background-dark-medium;

  i {
    display: inline-block;
    width: 8%;
    vertical-align: middle;
    font-size: 18px;
    margin-left: 5%;
  }
  h5 {
    display: inline-block;
    margin: 10px 5% 10px 0;
    width: 47%;
  }
  p {
    display: inline-block;
    text-align: right;
    width: 30%;
    margin-right: 5%;
    font-size: 12px;
  }
  &:hover {
    background: $accent-color;
    color: #fff;
  }
  &:hover h5 {
    color: #fff;
  }
}

#sidebar-participant-resume {
  background-color: $accent-color-2;
}

.sidebar-participant-resume-wrapper {
  margin-bottom: 20px;
  border-bottom: 1px solid $accent-color-3;
  .pr-title {
    font-size: 11px;
    text-align: center;
    padding-bottom: 5px;
  }
}

.sidebar-list-item-info-inboxResume-noAttachment h5 {
  margin-left: 13%;
}
.sidebar-list-item-info-inboxResume-noAttachment:hover {
  background: $background-dark-medium;
}

/***** SIDEBAR TAGS CONTAINER STYLES *****/
.tags-container h3 {
  display: inline-block;
}
.resume-tags {
  opacity: 1;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  border: 1px solid $divider;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #eaeaea;
  color: $secondary-text;
  width: auto;
  &:hover {
    background-color: $accent-color;
    border: 1px solid $accent-color;
    color: #fff;
    .remove-tags {
      color: #fff;
    }
  }
}
.remove-tags {
  margin-left: 8px;
  color: $secondary-text;
}
.resume-tag-input {
  display: none;
}
.resume-tag-input-active {
  display: block;
  border: none;
  border-bottom: 1px solid $divider;
  outline: none;
  &::-webkit-input-placeholder {
    color: $divider;
  }
  &:focus {
    border-bottom: 1px solid $primary-color;
  }
}
.resume-tag-suggestions {
  ul {
    padding-left: 0;
    padding-top: 10px;
    li {
      list-style-type: none;
      span mark {
        background-color: transparent;
      }
    }
  }
}

/***** CHANI SIDEBAR BUTTONS *****/
.sidebar-buttons {
  margin: -25px -20px 25px -20px;
  border-bottom: 1px solid $divider;
  position: fixed;
  width: 350px;
  background-color: $background-dark-medium;
  z-index: 1;
}
.sidebar-buttons p,
.sidebar-buttons a {
  display: inline-block;
  margin-bottom: 0;
}
.sidebar-buttons p {
  width: 48%;
  margin-right: 2%;
}
.sidebar-buttons a {
  width: 48%;
  margin-left: 2%;
  text-align: right;
}

/***** CHANI SIDEBAR TAGS STYLES *****/
.tags .rw-list-empty {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f5f5f5;
}
//.tags .rw-create-list-option {color: $accent-color !important;}
.tags .rw-create-list-option:hover {
  background-color: #e6e6e6 !important;
}
.group-tags {
  white-space: normal;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
  margin: 1px;
  line-height: 1.4em;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  color: #333;
}
