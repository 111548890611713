.mui-col-xs-1,
.mui-col-sm-1,
.mui-col-md-1,
.mui-col-lg-1,
.mui-col-xs-2,
.mui-col-sm-2,
.mui-col-md-2,
.mui-col-lg-2,
.mui-col-xs-3,
.mui-col-sm-3,
.mui-col-md-3,
.mui-col-lg-3,
.mui-col-xs-4,
.mui-col-sm-4,
.mui-col-md-4,
.mui-col-lg-4,
.mui-col-xs-5,
.mui-col-sm-5,
.mui-col-md-5,
.mui-col-lg-5,
.mui-col-xs-6,
.mui-col-sm-6,
.mui-col-md-6,
.mui-col-lg-6,
.mui-col-xs-7,
.mui-col-sm-7,
.mui-col-md-7,
.mui-col-lg-7,
.mui-col-xs-8,
.mui-col-sm-8,
.mui-col-md-8,
.mui-col-lg-8,
.mui-col-xs-9,
.mui-col-sm-9,
.mui-col-md-9,
.mui-col-lg-9,
.mui-col-xs-10,
.mui-col-sm-10,
.mui-col-md-10,
.mui-col-lg-10,
.mui-col-xs-11,
.mui-col-sm-11,
.mui-col-md-11,
.mui-col-lg-11,
.mui-col-xs-12,
.mui-col-sm-12,
.mui-col-md-12,
.mui-col-lg-12 {
  padding: 0;
}
.mui-row {
  margin: 0;
}

.mui-col-md-10 {
  width: 80%;
  margin: 0 auto;
}

.mui-radio,
.mui-checkbox {
  margin-top: 0;
}

.mui-container-fluid {
  padding: 0;
  margin: 0 auto;
}
.mui-container {
  padding: 0 15px;
  margin: 0 auto;
}

.mui-panel {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

h5 {
  font-weight: 600;
  margin-top: 0;
}
h5:last-child {
  margin-bottom: auto;
}

.arrow_1d5fttb-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo {
  right: 70px !important;
}

.rw-state-focus {
  background: transparent !important;
  border: 1px solid transparent !important;
  outline: 0 !important;
}
.rw-popup {
  border-radius: 4px !important;
}

._hj-f5b2a1eb-9b07_widget {
  z-index: 999 !important;
}
.rrm-holder {
  z-index: 10001 !important;
}

*:focus {
  outline: none;
}

@media (min-width: 1200px) {
  .mui-container,
  .mui-container-fluid {
    max-width: 1170px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .mui-container,
  .mui-container-fluid {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .mui-container,
  .mui-container-fluid {
    max-width: 740px;
  }
}

@media (min-width: 544px) and (max-width: 768px) {
  .mui-container {
    max-width: 570px;
  }
}
