.not-found-left p {
  font-size: 350px;
  color: $primary-color;
  font-weight: bold;
  margin-top: -145px;
  margin-left: -20px;
}
.not-found-right {
  position: absolute;
  right: 150px;
  bottom: 100px;
}
.not-found {
  position: relative;
  margin-bottom: 35px;
}
.oops {
  font-size: 200px;
  font-weight: bold;
  position: absolute;
  top: -250px;
  left: -75px;
  color: $background-dark;
}
.page-not-found {
  position: absolute;
  top: -100px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
}
.not-found-reasons {
  font-size: 16px;
  text-transform: uppercase;
  max-width: 530px;
  color: #8e8e8e;
}

@media only screen and (max-width: 1400px) {
  .not-found-right {
    right: 50px;
    bottom: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .not-found-left p {
    margin-top: 0;
    text-align: center;
  }
  .not-found-right {
    margin-top: 150px;
    bottom: auto;
  }
}

@media only screen and (max-width: 600px) {
  .not-found-left p {
    font-size: 175px;
    padding-left: 15px;
    margin-top: 75px;
  }
  .not-found-right {
    display: block;
    position: static;
    width: 90%;
    margin: -100px auto 0 auto;
    text-align: center;
    .btn-flat {
      margin-top: 15px;
    }
  }
  .oops {
    display: none;
  }
  .not-found {
    margin-top: 100px;
    margin-bottom: 10px;
  }
  .page-not-found {
    position: static;
    font-size: 25px;
  }
  .not-found-reasons {
    line-height: 25px;
    font-size: 13px;
    text-transform: none;
    margin-top: 15px;
  }
}
