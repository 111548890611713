.notifications-bar-container {
  background: $accent-color-2;
  color: #fff;
  padding: 10px 20px;

  p {
    margin: 0 20px;
    font-weight: 600;
  }
}

.owner-bar {
  p {
    display: inline-block;
    margin: 0 8px 8px 0;
  }
}

@media only screen and (max-width: 530px) {
  .notifications-bar-container {
    text-align: center;
  }
  .notifications-bar-container span {
    display: block;
    width: 120px;
    margin: 4px auto 0 auto;
  }
}
