.progress-material {
  width: 100%;
  height: 4px;
  background-color: rgba(33, 150, 243, 0.2);
  position: absolute;
  .progress-indeterminate {
    width: 20%;
    height: 100%;
    background-color: #2196f3;
    position: relative;
    top: 0;
    left: 0%;
    will-change: left, right;
    animation: indeterminate 1s ease-in infinite;
    overflow: hidden;
  }
}

@keyframes indeterminate {
  0% {
    transform: translate(0%);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translate(100%);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate(200%);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate(300%);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate(400%);
    animation-timing-function: ease-in;
  }
}
