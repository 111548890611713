input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="tel"],
input[type="week"],
select,
textarea {
  border-color: #e0e0e0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  border-width: 1px;
  border-style: solid;
  color: #474e57;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  height: auto;
  transition: all 200ms linear;
  background: #fff;
}

input[type="text"]:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

input[type="radio"]:focus {
  outline: 0;
  outline-offset: 0;
}

input[type="color"]:hover,
input[type="date"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="email"]:hover,
input[type="month"]:hover,
input[type="number"]:hover,
input[type="password"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="text"]:hover,
input[type="time"]:hover,
input[type="url"]:hover,
input[type="week"]:hover,
textarea:hover {
  border-color: $background-dark-medium !important;
}

input[type="email"]:hover,
input[type="password"]:hover,
input[type="tel"]:hover,
input[type="text"]:hover,
textarea:hover {
  border-color: $background-dark !important;
}

.search input[type="text"]:hover {
  border: 0 !important;
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
textarea:focus,
select:focus {
  border-color: $accent-color;
  //-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(160, 160, 160, 0.7);
  //box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(160, 160, 160, 0.7);
  outline: none;
}

.mui-textfield > input:focus {
  margin: 0 !important;
}

input[type="checkbox"],
input[type="image"],
input[type="radio"] {
  width: auto;
}

input[type="radio"] {
  margin-right: 5px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 400;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 400;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 600;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 400;
}

input::placeholder,
textarea::placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 400;
}

.form-help {
  font-size: 11px !important;
  color: $accent-color-3 !important;
  margin-top: -5px;
  display: block !important;
}
.form-input-extra-text {
  font-size: 12px;
  padding-top: 5px;
  display: block;
  color: $accent-color-3;
  margin-bottom: 15px;
}
.form-inline {
  display: inline-block;
}
.form-inline-half {
  width: 47%;
  margin-right: 6%;
  display: inline-block;
  vertical-align: top;
}
.form-inline-half:last-child {
  margin-right: 0;
}
.form-inline-third {
  width: 31.3%;
  margin-right: 3%;
  display: inline-block;
}
.form-inline-third:last-child {
  margin-right: 0;
}
.form-inline-special {
  width: 44.3%;
  margin-right: 3%;
  display: inline-block;
}
.form-inline-special:last-child {
  margin-right: 0;
}
.form-inline-select {
  width: 20%;
}
.form-input-margin-bottom {
  margin-bottom: 25px;
}
.form-select {
  height: 42px;
}

.form-share {
  width: 100%;
  margin-bottom: 15px;

  input[type="radio"] {
    display: none;
  }

  //ul.rw-list > li.rw-list-option:hover, .rw-selectlist > li.rw-list-option:hover {background-color: transparent !important;}

  li[aria-checked="true"] {
    border: 1px solid $primary-color;
  }

  li.rw-list-option {
    vertical-align: top;
    padding: 10px;
    color: #333 !important;
  }
  li.rw-list-option:hover {
    background: $background-dark;
  }
  .rw-widget {
    border: 0;
    width: 100%;
  }
  .rw-list {
    padding: 0;
  }
  .rw-list li.rw-list-option {
    width: 30%;
    display: inline-block;
    margin: 0 5%;
    text-align: center;
    border: 1px solid $divider;
    min-height: 100px;
    background: #fcfcfc;
    padding: 0;
    border-radius: 3px;
    transition: all 300ms linear;
  }
  .rw-list li.rw-list-option:hover {
    background-color: $accent-color !important;
    border: 1px solid transparent !important;
    color: #fff !important;
  }
  .rw-list li.rw-list-option[aria-checked="true"] {
    background-color: $accent-color !important;
    color: #fff !important;
  }
  .rw-list li:first-child,
  .rw-list li:last-child {
    margin: 0;
  }
  .rw-list li.rw-list-option > label {
    font-size: 13px;
    padding: 11px 0;
    line-height: 75px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase;
  }
  .rw-list li.rw-list-option > label:focus {
    outline: none;
    border: 0;
  }

  .autosuggest-list-container {
    padding: 20px;
    border-bottom: 1px solid $divider;
    border-radius: 3px;
    margin-bottom: 30px;
  }
  //img {max-width: 100%; height: auto; margin-bottom: 16px;}
}

@media only screen and (max-width: 544px) {
  .form-share .rw-selectlist > ul > li.rw-list-option {
    min-height: initial;
  }
  .form-share .rw-selectlist > ul > li.rw-list-option > label {
    line-height: 40px;
  }
}

.form-select-access {
  width: 80%;
  margin-bottom: 20px;
}
.form-textarea:focus {
  border-bottom: 1px solid $divider;
}
.form-textarea-note {
  box-shadow: none;
  padding: 0;
}

.form-error-message {
  font-size: 12px;
  color: red;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}

.inline-radio {
  display: inline-block;
  padding-top: 10px;
}

ul.rw-list.inline-radio,
.rw-selectlist.inline-radio {
  padding-top: 10px !important;
}

//.invalid-field {border-bottom: 1px solid red !important; border-radius: 0!important; margin-bottom: 3px !important;}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .form-inline-half {
    width: 100%;
    margin-right: 0;
    display: block;
  }
  .form-inline-third {
    width: 100%;
    margin-right: 0;
    display: block;
  }
  .mui-panel input[type="text"],
  input[type="password"],
  select {
    margin-bottom: 16px;
  }
  .page-account-content .inline-radio {
    padding-top: 0 !important;
    margin-bottom: 10px !important;
  }
  .account-settings-action {
    margin-top: 0 !important;
  }
  .account-settings-fields input[type="text"] {
    margin-bottom: 8px;
  }
  .inbox-settings-select select {
    margin-bottom: 0;
  }
  .filter-bar-select select {
    margin-bottom: 0;
  }
}

//#form {overflow: auto;}
#form h3 {
  font-weight: 700;
  margin: 0 0 15px 0;
  color: $accent-color;
}
#form h4 {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin: 0 0 7px 0;
  letter-spacing: 1px;
  color: #000000;
}

#form legend {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin: 0 0 7px 0;
  letter-spacing: 1px;
  color: #000000;
  padding: 0;
  border-bottom: none;
}
#form span.has-error {
  text-transform: none;
  display: inline-block;
  font-size: 11px;
  letter-spacing: normal;
  font-weight: 600;
  margin-left: 5px;
  color: red;
}

#form .resize {
  resize: none;
}

#form .dragDrop {
  border-color: #e0e0e0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-width: 2px;
  border-style: dashed;
  color: #474e57;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 20px 10px;
  width: 100%;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
  clear: both;
}

#form .dragDropActive {
  background: #e0e0e0;
}
#form .dragDropPreview {
  width: 88px;
  height: 88px;
}
#form .dragDropFileBtn {
  background: $accent-color !important;
  border-radius: 3px;
  padding: 6px 3px;
  display: block;
  width: 25%;
  margin: 0 auto;
  color: #fff;
  margin-top: 10px;
  transition: all 300ms linear;
}
#form .dragDropFileBtn:hover {
  background: $accent-color-2 !important;
  cursor: pointer;
}
#form .previewExists {
  display: none;
}

#form .mui-panel {
  padding: 5%;
}

#form legend.circle {
  background: #e0e0e0;
  border-radius: 2.8em;
  -moz-border-radius: 2.8em;
  -webkit-border-radius: 2.8em;
  color: #4a4a4a;
  display: inline-block;
  font-weight: bold;
  line-height: 2.6em;
  margin-right: 15px;
  text-align: center;
  width: 2.6em;
  margin-top: 30px;
  height: 28px;
  float: left;
}
#form legend.circle span {
  padding-left: 2px;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  #form legend.circle {
    float: none;
    display: block;
  }
}

#form .form-heading {
  margin-bottom: 35px;
}
.groupPage .form-subheading {
  font-weight: 600;
  color: #4a4a4a;
  font-size: 12px;
  text-transform: uppercase;
}

#form .mui-textfield {
  padding: 0;
}
#form .mui-row {
  margin-bottom: 25px;
}

#form .mui-radio > label,
#form .mui-checkbox > label {
  color: #616161;
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
}

#form .picPreview {
  position: relative;
  width: 130px;
  height: 130px;
}
#form .picPreview p {
  position: absolute;
  top: 0;
  right: 5px;
}

.engaged-checkbox {
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 20px;

  input {
    margin-right: 10px;
  }
}

/***** CHANIS ADDED STYLES *****/
.inline-radio.rw-widget {
  border: none;
  ul li {
    display: inline-block;
  }
  ul.rw-list > li.rw-list-option:hover,
  .rw-selectlist > li.rw-list-option:hover {
    border-color: transparent;
    background-color: transparent;
  }
}
.legend-cbx {
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}

/***** React Widget Styles *****/
ul.rw-list,
.rw-selectlist {
  padding: 0 !important;
}
.sidebar-content-section .rw-multiselect {
  background: $background-dark-medium !important;
  border: 0 !important;
  border-bottom: 1px solid $background-dark !important;
  border-radius: 3px;
  box-shadow: none !important;
}
.sidebar-content-section .rw-multiselect-wrapper {
  padding: 5px 0;
}
.rw-multiselect-taglist > li {
  white-space: normal !important;
  text-align: left !important;
  max-width: 100%;
  word-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
  margin: 1px;
  line-height: 1.4em;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
}
.rw-select {
  display: none !important;
}
.rw-widget *:focus {
  outline: none;
  border: none;
}
.rw-widget input[type="text"]:focus {
  border: 1px solid #f03b74;
}
//.rw-widget-input {box-shadow: none !important;}
.rw-widget-input.rw-state-focus {
  box-shadow: none !important;
}
.sidebar-content-section .rw-multiselect-taglist input {
  background: transparent;
}
.sidebar-content-section .rw-list-option {
  padding: 10px;
  border: 0 !important;
}
.sidebar-content-section .rw-multiselect-wrapper > .rw-input {
  background: transparent;
  padding: 0;
}
.sidebar-content-section ul.rw-list {
  max-height: 120px;
}
.sidebar-content-section ul.rw-list > li.rw-list-option.rw-state-focus,
.rw-selectlist > li.rw-list-option.rw-state-focus {
  border: 0 !important;
}
.sidebar-content-section ul.rw-list > li.rw-list-option.rw-state-focus:hover,
.rw-selectlist > li.rw-list-option.rw-state-focus:hover {
  background: $background-dark-medium;
}
.rw-tag-btn {
  margin-left: 5px;
}
.rw-popup {
  box-shadow: none !important;
}
ul.rw-list > li.rw-list-option,
.rw-selectlist > li.rw-list-option {
  word-wrap: break-word;
}
.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover {
  color: #333 !important;
}
.resume-fields .rw-widget-container {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.sidebar-content-section .rw-widget-container {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.edit-group-form .rw-widget-container {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

//.radioBtn {width: 100%;}
//.radioBtn ul {width: 100%;}
//.radioBtn label, .radioBtn input {display:block; position:absolute; top:0; left:0; right:0; bottom:0;}
//.radioBtn.rw-selectlist > ul > li.rw-list-option {width: 47%; margin: 0 1.5%;}
//.radioBtn.rw-selectlist > ul > li.rw-list-option > label > input {margin: 0;}
//.radioBtn input[type="radio"] {opacity:0.011; z-index:100; width: 100%;}
//
//.radioBtn.rw-selectlist > ul > li.rw-list-option > label {
//  padding:5px;
//  font-size: 12px;
//  border:1px solid #CCC;
//  border-radius: 3px;
//  text-align: center;
//  cursor:pointer;
//}
//
//.radioBtn li[aria-checked="true"] {background-color: $background-dark; color: $accent-color-3;}
//.radioBtn li[aria-checked="true"] label {border: 0 !important; border-radius: 3px;}
//
//.radioBtn .rw-state-focus{
//  border: 0 !important;
//  outline: none !important;
//  background-color: $accent-color !important;
//  color: #fff;
//}

.field-margin-bottom {
  margin-bottom: 15px;
}

/***** REACT TEL STYLES *****/
.react-tel-input {
  margin-bottom: 25px;
}
.react-tel-input input[type="tel"] {
  height: 42px !important;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06) !important;
  border-color: #e0e0e0 !important;
}
.react-tel-input .flag-dropdown .selected-flag {
  height: 40px !important;
}

.location-container input[type="text"] {
  margin-bottom: 25px;
}

#PlacesAutocomplete__autocomplete-container {
  margin-top: 0 !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
  border: 1px solid #ccc !important;
  border-radius: 0 0 3px 3px !important;
}

@media only screen and (max-width: 520px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
  .search-filter .search input[type="text"] {
    font-size: 16px;
  }
}
