/***** STEPPER STYLES *****/
.steps {
  margin: 0 auto 0 auto;
  text-align: center;
  max-width: 100%;
}
.steps .content {
  margin: 10px 0 10px 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.steps .content:before {
  content: "";
  width: 80%;
  height: 2px;
  background-color: $divider;
  position: absolute;
  top: 13px;
  left: 10%;
  z-index: 1;
}
.steps .content:after {
  content: "";
  width: 0;
  height: 5px;
  position: absolute;
  top: 13px;
  left: 10%;
  z-index: 2;
}
.steps .content.step-2:after {
  width: 20%;
}
.steps .content.step-3:after {
  width: 40%;
}
.steps .content.step-4:after {
  width: 60%;
}
.steps .content.step-5:after {
  width: 80%;
}
.steps ul {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
}
.steps li {
  float: left;
  width: 20%;
  padding: 0;
  position: relative;
  z-index: 3;
  cursor: pointer;
}
.steps li b {
  text-indent: -999999px;
  display: block;
  width: 15px;
  height: 15px;
  line-height: 18px;
  border-radius: 50%;
  border: 2px solid $divider;
  background: $primary-color-dark;
  margin: 5px auto 5px auto;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}
.steps li span {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  line-height: 1.2;
  color: #fff;
}
.steps .step-active b {
  border: 2px solid #fff;
  background: $accent-color;
}

/***** CREATE RESUME GENERAL STYLES *****/
.createResumePage {
  select {
    height: 42px;
  }
  .mui-row {
    padding: 0 2%;
  }
}
.create-resume-page {
  width: 75%;
  float: left;
}

@media only screen and (max-width: 992px) {
  .create-resume-page {
    width: 100%;
    float: none;
  }
}

/***** CREATE RESUME SECTION TEXT STYLES *****/
.resume-title-header {
  border-bottom: 3px solid grey;
  padding-bottom: 15px;
  margin-bottom: 25px;
  clear: both;
  span {
    margin-left: 4px;
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    color: $accent-color-3;
    line-height: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-transform: uppercase;
  }

  .validation-error {
    margin-left: 10px;
    font-size: 11px;
    padding: 0 8px;
    float: right;
    background: red;
    border-radius: 3px;
    color: white;
    margin-top: 4px;
  }
}

@media only screen and (max-width: 768px) {
  .resume-title-header .validation-error {
    float: none !important;
    margin-left: 0 !important;
  }
}

/***** GENERAL INFO CALENDAR STYLES *****/
.rw-popup-container.rw-calendar-popup td {
  padding: 0 !important;
}
.resume-fields .rw-popup-container.rw-calendar-popup td {
  padding: 0 !important;
  font-size: 14px !important;
  height: 34px;
  font-weight: normal;
}
.resume-fields .rw-calendar-grid th {
  text-align: center !important;
}
.rw-calendar-grid.rw-nav-view .rw-btn {
  padding: 0 !important;
}

/***** ADD ITEM STYLES *****/
.resume-addItem {
  color: $accent-color-3;
  font-size: 11px;
  font-weight: 600;
  transition: all 300ms linear;
  padding: 5px 10px !important;
  display: inline-block;
  border-radius: 3px;
  float: right;
  background: $background-dark;
  .fa {
    margin-right: 5px;
  }

  &:hover {
    color: $primary-color;
    cursor: pointer;
  }
}

.resume-addItem-simple {
  background: none;
  color: $accent-color-3;
}

@media only screen and (max-width: 768px) {
  .resume-addItem {
    margin-bottom: 8px;
  }
}

/***** ADDITIONAL ITEM STYLES *****/
.resume-fields {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li.additional-item {
    border-bottom: 1px dashed $divider;
    margin-bottom: 10px;
    transition: all 300ms linear;
  }
  li.additional-item .mui-row {
    clear: both;
  }
  li.additional-item:hover {
    background: $background-dark;
    border-radius: 3px;
    border-bottom: 1px solid $background-dark-medium;
  }
}
.resume-fields-close {
  float: right;
  color: $accent-color-3;
  transition: all 300ms linear;
  padding-right: 10px;
  margin-right: -8px;
}
.resume-fields-close:hover {
  color: $accent-color-2;
  cursor: pointer;
}

/***** CREATE RESUME TYPE STYLES *****/
.type-amount .fa-times {
  color: $accent-color-3;
  transition: all 300ms linear;
}
.type-amount .fa-times:hover {
  color: $accent-color-2;
  cursor: pointer;
}
.type-amount-filled {
  color: $accent-color !important;
}
.createResumePage .type-amount .number:hover .fa-circle {
  color: $accent-color-2;
  cursor: pointer;
}
.type-amount .number.number-filled:after {
  color: #fff;
}
.type-amount .number {
  position: relative;
  cursor: pointer;
}
.type-amount .number:after {
  position: absolute;
  top: 3px;
  left: -1px;
  font-size: 8px;
  color: #999;
  height: 100%;
  width: 100%;
  text-align: center;
}
.type-amount .number:nth-child(1):after {
  content: "1";
}
.type-amount .number:nth-child(2):after {
  content: "2";
}
.type-amount .number:nth-child(3):after {
  content: "3";
}
.type-amount .number:nth-child(4):after {
  content: "4";
}
.type-amount .number:nth-child(5):after {
  content: "5";
}
.type-amount .number:nth-child(6):after {
  content: "6";
}
.type-amount .number:nth-child(7):after {
  content: "7";
}
.type-amount .number:nth-child(8):after {
  content: "8";
}
.type-amount .number:nth-child(9):after {
  content: "9";
}
.type-amount .number:nth-child(10):after {
  content: "10";
}
@media only screen and (min-width: 520px) {
  .createResumePage .type-amount .fa-circle {
    font-size: 20px;
  }
  .type-amount .number:after {
    font-size: 10px;
    top: 0;
  }
}
.resume-fields {
  tr {
    border-bottom: 1px dashed $divider;
  }
  tr td:first-child {
    width: 50%;
    min-width: 185px;
    color: $secondary-text;
    font-size: 12px;
    vertical-align: top;
    font-weight: 600;
  }
  td {
    padding: 10px 0;
  }
  .resume-table-title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    margin: 0 0 7px 0;
    letter-spacing: 1px;
    color: $accent-color-3;
  }
}

@media only screen and (max-width: 768px) {
  .type-amount {
    text-align: right;
  }
  .resume-section .resume-fields tr td:first-child {
    max-width: 156px;
  }
}

/***** CONTACT CHECKBOX STYLES *****/
.contact-checkbox {
  margin-left: 15px;
  text-transform: capitalize;
}
.contact-checkbox span {
  margin-right: 10px;
}
.contact-checkbox label {
  vertical-align: middle;
  color: $accent-color;
  font-size: 12px;
  text-transform: none;
}

@media only screen and (max-width: 768px) {
  .contact-checkbox {
    display: block;
    margin-left: 0 !important;
  }
}

/***** CREATE RESUME REFERENCE STYLES *****/
.reference-item {
  width: 95% !important;
  margin-bottom: 10px;
}

/***** CREATE RESUME PHOTOS STYLES *****/
.resume-pic-container {
  display: inline-block;
  position: relative;
}
.resume-pic-container .fa-trash {
  display: none;
  position: absolute;
  top: 2px;
  font-size: 22px;
  color: #fff;
  left: 8px;
  margin-left: -8px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  height: 88px;
  width: 88px;
  padding-top: 32px;
  padding-left: 36px;
  border-radius: 3px;
}
.resume-pic-container:hover .fa-trash {
  display: block;
  cursor: pointer;
}
.resumePicPreview {
  float: left;
  margin-bottom: 24px;
}
.dragDropPreview {
  border-radius: 3px;
}
.resume-section .dragDrop {
  height: 120px;
  width: 176px !important;
  display: inline-block;
  margin-top: 0 !important;
}
.dragDrop h4 {
  font-size: 8px !important;
  letter-spacing: 0.25px !important;
}
.resume-fields .dragDropFileBtn {
  width: auto !important;
  padding: 0 !important;
  margin-top: 12px !important;
  height: 24px;
  line-height: 24px;
}
.make-primary {
  display: block;
  position: absolute;
  text-align: center;
  width: 100%;
  margin-top: 2px;
  font-size: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  color: $accent-color;
  transition: all 300ms linear;
}
.make-primary:hover {
  color: $primary-color;
}

/***** CREATE RESUME SIDEBAR STYLES *****/
.resume-sidebar-container {
  position: relative;
  padding: 61px 35px 15px 35px;
  width: 25%;
  float: left;

  .resume-myOwn {
    color: $primary-color;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 25px;
    span {
      margin-left: 10px;
    }
  }

  .resume-sidebar {
    padding-top: 1%;
    padding-left: 65px;
    position: fixed;
    min-width: 300px;

    .resume-messageBar {
      margin-bottom: 30px;
      border-bottom: 1px solid $divider;
      padding-bottom: 25px;
      p {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
      }
      p.saved {
        color: $accent-color;
      }
      p.saving {
        color: $primary-color;
      }
      p.unsaved {
        color: $accent-color-2;
      }
    }
    .validation-error-general {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      color: red;
      margin-bottom: 25px;
      display: block;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .resume-sidebar {
    padding-left: 24px !important;
    min-width: 190px !important;
  }
}

@media only screen and (min-width: 992px) {
  .resume-messageBar {
    position: fixed;
    width: 14%;
  }
}

@media only screen and (max-width: 991px) {
  .resume-sidebar-container {
    width: 100% !important;
  }
  .resume-sidebar {
    padding-left: 0 !important;
    position: static !important;
  }
}
@media only screen and (max-width: 768px) {
  .resume-sidebar-container .resume-sidebar {
    position: static;
    padding-left: 0;
  }
}

/***** CREATE RESUME MOBILE SPACING STYLES *****/
@media only screen and (max-width: 768px) {
  .createResumePage {
    input[type="text"],
    select {
      margin-bottom: 16px;
    }
    .react-tags.rw-widget {
      margin-bottom: 16px;
    }
    .resume-section input[type="checkbox"] {
      margin-top: 0;
      margin-bottom: 16px;
    }
    table {
      width: 100%;
    }
    .mui-row {
      margin-bottom: 0 !important;
    }
    .inline-radio {
      padding-top: 0 !important;
      margin-bottom: 8px;
    }
    .rw-datetimepicker input[type="text"] {
      margin-bottom: 0;
    }
    .rw-datetimepicker {
      margin-bottom: 16px;
    }
  }
}
