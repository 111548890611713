/********** SIDEBAR - LEFT LIST STYLES **********/
.sidebar-column-small {
  width: 5%;
  margin-right: 2%;
  display: inline-block;
}
.sidebar-column-medium {
  width: 22%;
  display: inline-block;
}
.sidebar-column-large {
  width: 53%;
  display: inline-block;
}
.sidebar-column-extraLarge {
  width: calc(53% + 40px + 5%);
  display: inline-block;
}

.sidebar-list {
  background: #ffffff;

  //.container.infinite {height: calc(100vh - 123px); overflow-y: scroll; width: 100%;}

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  .sidebar-list-item {
    border-bottom: 1px solid $divider;
    padding: 15px 20px;
    transition: all 0.2s linear;
    //border-right: 2px solid #ffffff;
    &:hover {
      background: $hover-color;
      cursor: pointer;
      //border-right: 2px solid $background-dark;
    }
    .sidebar-list-item-myResume {
      margin-top: 5px;
    }
    .sidebar-list-item-myResume p {
      background: $divider;
      border-radius: 3px;
      color: $secondary-text;
      font-size: 9px;
      line-height: 9px;
      font-weight: 600;
      padding: 4px;
      text-align: center;
      margin: 0;
      width: 60px;
      float: right;
      text-transform: uppercase;
    }

    .sidebar-list-item-unread,
    .sidebar-list-item-read {
      font-size: 10px;
      vertical-align: top;
      line-height: 40px;
      color: $accent-color;
    }
    .sidebar-list-item-read {
      text-indent: -99999px;
    }
    .sidebar-img-container {
      display: inline-block;
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin-right: 5%;
      vertical-align: top;
    }
    .sidebar-list-item-image {
      width: 100%;
      height: auto;
      border-radius: 3px;
      span {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: $background-dark;
        display: inline-block;
        text-align: center;
        border-radius: 3px;
        text-transform: uppercase;
      }
    }
    .sidebar-list-item-info {
      .sidebar-list-item-title {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 17px;
        color: $accent-color-3;
      }
      .sidebar-list-item-unread-title {
        font-weight: bold;
      }
      .sidebar-list-item-info-details,
      .sidebar-list-item-info-types {
        margin-bottom: 0;
        line-height: 16px;
      }
      .sidebar-list-item-info-details span {
        margin-right: 10px;
        font-size: 12px;
        font-family: "Open Sans";
        color: $secondary-text;
      }
      #resumeList.sidebar-list-item-info-details span:first-of-type {
        display: inline-block;
        width: 16px;
        text-align: center;
      }
      .sidebar-list-item-info-types span {
        font-size: 10px;
        font-family: "Open Sans";
        color: $secondary-text;
        font-style: italic;
      }
    }
    .sidebar-list-item-extra {
      vertical-align: top;
      color: $accent-color-2;
      text-align: right;
      font-size: 12px;
      text-transform: lowercase;
    }
    .sidebar-list-item-extra .fa {
      padding-left: 5px;
      margin-left: 5px;
    }
    .sidebar-list-item-extra .border {
      color: $primary-color;
      font-size: 11px;
      display: block;
    }
    .sidebar-list-item-extra .pending {
      color: $accent-color-2;
      font-size: 11px;
      display: inline-block;
    }
  }
  .sidebar-list-item-info-details.full-name {
    font-size: 10px;
    font-style: italic;
    color: $accent-color-3;
  }

  .sidebar-list-item-active {
    background: $active-color; /*border-right: 2px solid $primary-color;*/
  }
}

.sidebar-list::-webkit-scrollbar {
  width: 12px;
}
.sidebar-list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.sidebar-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $divider;
}

.sidebar-list-count {
  padding: 8px 20px 4px 20px;
  p {
    font-size: 9px;
    text-align: right;
  }
}

.infinite > div:first-of-type {
  margin-bottom: -14px !important;
}
