.shadchan-profile-container {
  max-width: 1000px;
  margin: 0 auto;
}

.shadchan-actions-container {
  width: 100%;
  height: 69px;
  position: fixed;
  z-index: 1;
  border-bottom: 1px solid #e1e1e1;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 20px;
  .back {
    display: flex;
    align-items: center;
    font-size: 11px;
    width: 110px;
    margin-right: 5px;
  }
  .action-item {
    padding: 10px;
    color: #ffffff;
    border-radius: 3px;
    background-color: $primary-color;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .action-item-send-msg {
    margin-left: 16px;
    cursor: pointer;
  }
  .action-item-pending {
    background-color: $accent-color-3;
  }
  .action-item-accepted {
    background-color: $accent-color-2;
  }
  .action-item-denied {
    background-color: $said-no;
  }
  .action-item-icon {
    margin-right: 6px;
  }
}

@media only screen and (min-width: 720px) {
  .shadchan-actions-container {
    .back {
      display: none !important;
    }
  }
}

.my-shadchan-header-info {
  display: flex !important;
  justify-content: space-between;
}

.my-shadchan-edit-button {
  background-color: $accent-color;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  height: 36px;
}

.shadchan-edit-container {
  margin-top: 20px;
  position: relative;
}

.cancel-shadchan-form {
  font-size: 26px;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
