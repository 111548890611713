/***** MODAL STYLES *****/
.modal-container {
  background: rgba(50, 58, 68, 0.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
  opacity: 1;
}
.modal {
  background: #fff;
  border-radius: 3px;
  max-height: 98vh;
  overflow: auto;
  position: relative !important;
}
div[aria-label="Modal"]:focus {
  outline: none;
}
.modal:focus {
  outline: none;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: $background-light;
  border-bottom: 1px solid #e1e1e1;
}
.modal-header p {
  color: $primary-color;
  font-size: 18px;
  margin-bottom: 0;
}
.modal-close-icon {
  cursor: pointer;
  color: #727272;
  transition: all 200ms linear;
}
.modal .ion-ios-close-empty {
  font-size: 28px;
  color: #9b9b9b;
  cursor: pointer;
  margin-left: 16px;
}
.modal .ion-ios-close-empty:hover {
  transition: all 300ms linear;
  color: #2d355c;
}
.modal-content-container {
  padding: 24px;
  min-height: 342px;
}
.rw-filter-input {
  width: 98.5%;
}

/*** Open Modal Styles ***/
.modal-open .modal {
  display: block;
}

/*** Modal Sizes ***/
.medium-modal {
  width: 520px;
}
.large-modal {
  width: 800px;
}

@media only screen and (max-width: 824px) {
  .large-modal {
    width: 520px;
  }
}

@media only screen and (max-width: 536px) {
  .large-modal,
  .medium-modal {
    width: 312px;
  }
}
/*** Verify Modal Styles ***/
.verify-modal .has-error {
  text-transform: none;
  display: inline-block;
  font-size: 11px;
  letter-spacing: normal;
  font-weight: 600;
  color: red;
}
.verify-modal .picPreview {
  margin: 0 auto;
}
.verify-modal .dragDropFileBtn {
  width: 25% !important;
}

/*** Welcome Modal Styles ***/
.react-redux-modal .rrm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.react-redux-modal .rrm-holder .scroll .rrm-content {
  z-index: 1;
  position: relative;
  box-shadow: 0 0 20px #333;
  margin: 80px auto;
  overflow: visible;
}
.react-redux-modal .rrm-holder .scroll .rrm-content.m-medium {
  width: 520px;
}
.react-redux-modal .rrm-holder .rrm-shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(50, 58, 68, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/*** Idea Modal Styles ***/
.idea-modal {
  overflow: hidden;
  .mui-panel {
    border-radius: 3px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .suggested {
    border-left: 5px solid $suggested;
  }
  .researching {
    border-left: 5px solid $researching;
  }
  .busy {
    border-left: 5px solid $busy;
  }
  .said-yes {
    border-left: 5px solid $said-yes;
  }
  .said-no {
    border-left: 5px solid $said-no;
  }
  .waiting {
    border-left: 5px solid $waiting;
  }
}
.idea-modal .modal-header-left {
  display: flex;
  align-items: center;
}
.modal-header .btn-modal {
  margin-left: 24px;
  display: inline-block;
  color: $accent-color-3;
  transition: all 300ms linear;
  font-size: 12px;
}
.modal-header .btn-modal .mobile-hidden {
  margin-left: 4px;
}
.modal-header .btn-modal:hover {
  cursor: pointer;
  color: $accent-color;
  font-size: 12px;
}
.modal-subheader {
  background-color: $divider;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid $divider;
  border-bottom: 1px solid $divider;
  .mui-col-md-3 {
    text-align: center;
  }
  .mui-btn.mui-btn--flat:hover,
  .mui-btn.mui-btn--flat:focus,
  .mui-btn.mui-btn--flat:active {
    background-color: transparent;
  }
}
.modal-disabled-btn {
  color: $secondary-text !important;
  font-size: 15px;
  border: none;
  background: transparent;
  text-transform: uppercase;
  height: 36px;
  margin: 6px 0;
  cursor: pointer;
  transition: all 300ms linear;
}
.modal-active-btn {
  color: $accent-color !important;
  font-size: 15px;
  border: none;
  background: transparent;
  text-transform: uppercase;
  height: 36px;
  margin: 6px 0;
  cursor: pointer;
  transition: all 300ms linear;
}
.button-sliding-bg-active {
  background-color: $divider;
  display: inline-block;
  width: 25%;
}
.button-sliding-bg {
  color: #fff;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  width: 25%;
  box-shadow: inset 0 0 0 0 $divider;
  -webkit-transition: all ease 0.8s;
  -moz-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.autosuggest .form-inline-half {
  vertical-align: top;
}
.idea-card.idea-card-modal {
  border-bottom: none;
  padding-bottom: 20px;
}
.idea-card-modal .ideas-card-info {
  margin-top: 12px;
}
.idea-card-info-dropdown {
  margin-bottom: 0;
  padding-top: 0;
  margin-top: 8px;
  select {
    font-size: 14px !important;
    padding-left: 8px;
  }
  .mui-select__menu {
    height: 175px !important;
  }
}
.radio-btns {
  margin-bottom: 24px;
  legend {
    float: left;
    width: auto;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
.suggest-name-input {
  margin-left: 10px;
  margin-bottom: 0;
  input {
    font-size: 14px;
    height: 32px;
  }
}
.share-modal-toggle {
  float: right;
}
.share-modal-option {
  font-size: 17px;
  margin-bottom: 0;
}
.share-option-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  clear: both;
  border-top: 1px solid $divider;
}
.share-option-divider-two {
  margin-top: 5px;
  clear: both;
}
.subject-textarea {
  margin: 40px 0 0 0;
}
.share-mail-icon {
  color: $primary-color;
  vertical-align: middle;
  margin-right: 15px;
}
.subject-input {
  display: inline-block;
  width: 85%;
  margin-top: 0;
  margin-bottom: 0;
  input {
    height: 33px;
  }
}
.share-modal-error {
  color: red;
  display: inline-block;
  font-size: 11px;
  letter-spacing: normal;
  font-weight: 600;
  margin-left: 5px;
}

@media only screen and (max-width: 544px) {
  .idea-modal .share-modal-header p {
    width: auto;
  }
  .mobile-hidden {
    display: none;
  }
  .modal-subheader {
    margin-right: 0;
    margin-left: 0;
  }
  .modal-active-btn {
    font-size: 13px;
    padding: 0;
  }
  .modal-disabled-btn {
    font-size: 13px;
    padding: 0;
  }
}

/*** Add to Group Modal ***/
.add-to-group-modal .rw-popup {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

/*** Invite to Group Modal ***/
.invite-to-group-modal .rw-popup {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}

/*** Add Resume to Group Modal ***/
.add-resume-to-group-modal .autosuggest-dropdown .rw-list {
  max-height: 160px;
}

/*** Admin Modals ***/
.admin-modal .modal-header {
  justify-content: flex-start;
}
.admin-modal .modal-close-icon {
  position: absolute;
  right: 16px;
}
