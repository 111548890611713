#sidebar-left {
  position: fixed;
  left: 0;
  width: 350px;
  z-index: 1000;
}
#content-main {
  position: fixed;
  left: 350px;
  right: 350px;
  border-left: 1px solid $divider;
  background: $background-medium;
  height: 100%;
  z-index: 1000;
}
#sidebar-content {
  position: fixed;
  width: 0;
  transition: width 400ms linear;
  right: 0;
  max-height: 100vh;
  box-sizing: border-box;
}
#sidebar-content-content {
  background-color: $background-medium;
  border-left: 1px solid $divider;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  position: relative;
}
#sidebar-content .sidebar-content-content {
  display: none;
}
#sidebar-content.active {
  width: 350px !important;
  max-width: calc(100vw - 36px) !important;
  background: $background-dark;
  height: 100vh;
  z-index: 1000;
}

#content-main.column-layout-two,
#content-main.sidebar-hidden {
  right: 0;
}
#content-main.column-layout-three {
  left: 0;
  right: 0;
}

.sidebar-content-arrow {
  padding: 17px 10px;
  display: block;
  text-align: center;
  background: $accent-color;
  color: #ffffff;
  position: absolute;
  z-index: 1001;
  top: 101px;
  left: -31px;
  transition: all 300ms linear;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
}
.sidebar-content-arrow:hover {
  background: $primary-color-dark;
  color: #ffffff;
}

.column-half-left {
  float: left;
  width: 47.5%;
  margin-right: 2.5%;
}
.column-half-right {
  float: left;
  width: 47.5%;
  margin-left: 2.5%;
}

@media only screen and (max-width: 1200px) {
  #content-main {
    right: 0;
  }
}

@media only screen and (min-width: 720px) {
  #sidebar-left {
    width: 350px;
  }
  #content-main {
    left: 350px;
  }
  #sidebar-content {
    position: absolute;
    top: 0;
    right: 1px;
    width: 0;
  }
  //#sidebar-content-content {display: none;}

  #sidebar-content.active {
    position: absolute;
    right: 1px;
    width: 350px;
    -webkit-box-shadow: -10px -5px 5px -6px rgba(232, 232, 232, 1);
    -moz-box-shadow: -10px -5px 5px -6px rgba(232, 232, 232, 1);
    box-shadow: -10px -5px 5px -6px rgba(232, 232, 232, 1);
    border-left: 1px solid $divider;
  }
  #sidebar-content.active #sidebar-content-content {
    display: block;
  }
}

@media only screen and (max-width: 720px) {
  #sidebar-left {
    margin-top: 84px;
    padding-top: 0 !important;
    width: 100%;
    z-index: 2;
  }
  #content-main {
    left: 0;
    right: 0;
    position: relative;
    padding: inherit;
    padding-top: 84px;
  }
}

//@media only screen and (min-width: 520px) and (max-width: 630px) {
//	#sidebar-left {width: 200px;}
//	#content-main {left: 200px; right: 0;}
//
//	#sidebar-content {position:absolute; top: 0; right: 0; width: 0;}
//	#sidebar-content-content {display: none;}
//
//	#sidebar-content.active {position: absolute; right: 0; width: 350px;}
//	#sidebar-content.active #sidebar-content-content {display: block;}
//}
//
//@media only screen and (min-width: 320px) and (max-width: 520px) {
//	#sidebar-left {display: block; width: 100%;}
//	#content-main {display: none;}
//	#sidebar-content {display: none;}
//}
