.envelope {
  width: 200px;
  position: relative;
  display: block;
  margin: 8% auto;

  .envelope-outline {
    width: 150px;
    height: 100px;
    border: 4px solid $accent-color;
    animation: circle 1s 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    margin: 0 auto;
  }

  .triangle {
    border-left: 5px solid $accent-color;
    border-bottom: 5px solid $accent-color;
    height: 125px;
    width: 125px;
    position: absolute;
    top: -77px;
    left: 10px;
    transform: rotate(-45deg);
    animation: check 1s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    border-radius: 0 0 0 3px;
  }

  .dot {
    height: 8px;
    width: 8px;
    background-color: $accent-color;
    border-radius: 100%;
    position: absolute;
    opacity: 0;
  }

  .dot1 {
    animation: dot1 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .dot2 {
    animation: dot2 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .dot3 {
    animation: dot3 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .dot4 {
    animation: dot4 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .dot5 {
    animation: dot5 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .dot6 {
    animation: dot6 2s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

.envelope2 {
  @extend .envelope;

  .triangle {
    animation: x 1s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .triangle:before {
    position: absolute;
    content: "";
    background-color: $accent-color;
    height: 0;
    top: 21px;
    left: -27px;
    z-index: 2;
    width: 0;
    animation: x-line 1s 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

@keyframes circle {
  0% {
    border-radius: 0;
    width: 200px;
    height: 100px;
    margin: auto;
  }
  100% {
    border-radius: 100%;
    width: 75px;
    height: 72px;
    margin: auto;
    transform: rotate(180deg);
  }
}

@keyframes check {
  0% {
    height: 125px;
    width: 125px;
  }
  100% {
    height: 15px;
    width: 35px;
    top: 30px;
    left: 15px;
    margin: auto;
    transform: rotate(135deg);
  }
}

@keyframes x {
  0% {
    height: 125px;
    width: 125px;
  }
  100% {
    border-bottom: none;
    height: 50px;
    width: 50px;
    top: -6px;
    left: 27.5px;
  }
}

@keyframes x-line {
  0% {
    height: 0;
    width: 0;
  }
  100% {
    height: 5px;
    width: 48px;
  }
}

@keyframes dot1 {
  0% {
    opacity: 0;
    top: 16px;
    left: 63px;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    left: 25px;
    opacity: 0;
  }
}

@keyframes dot2 {
  0% {
    opacity: 0;
    top: 0;
    left: 95px;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -40px;
    left: 95px;
    opacity: 0;
  }
}

@keyframes dot3 {
  0% {
    opacity: 0;
    top: 16px;
    right: 63px;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    right: 25px;
    opacity: 0;
  }
}

@keyframes dot4 {
  0% {
    opacity: 0;
    bottom: 16px;
    right: 63px;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: 0;
    right: 25px;
    opacity: 0;
  }
}

@keyframes dot5 {
  0% {
    opacity: 0;
    bottom: 0;
    left: 95px;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: -40px;
    left: 95px;
    opacity: 0;
  }
}

@keyframes dot6 {
  0% {
    opacity: 0;
    bottom: 16px;
    left: 63px;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: 0;
    left: 25px;
    opacity: 0;
  }
}

.envelope-outline-small {
  @extend .envelope-outline;
  transform: scale(0.5);
  animation: circle-small 1s 1 !important;
  animation-fill-mode: forwards !important;
  animation-delay: 1s !important;
}

.triangle-small {
  @extend .triangle;
  animation: check-small 1s 1 !important;
  animation-delay: 1s !important;
  animation-fill-mode: forwards !important;
}

@keyframes circle-small {
  0% {
    border-radius: 0;
    width: 200px;
    height: 100px;
    margin: auto;
    transform: scale(0.5);
  }
  100% {
    border-radius: 100%;
    width: 75px;
    height: 72px;
    margin: auto;
    transform: rotate(180deg) scale(0.5) !important;
  }
}

@keyframes check-small {
  0% {
    height: 125px;
    width: 125px;
  }
  100% {
    height: 15px;
    width: 35px;
    top: 22px;
    left: 17px;
    margin: auto;
    transform: rotate(-230deg) !important;
  }
}

.modal-response {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
}
.modal-response p {
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 600;
}
