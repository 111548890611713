.inbox-header {
  margin: 20px 0 15px 0;

  .inbox-header-suggestion {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .inbox-header-resumes div {
    margin-bottom: 5px;
  }
  .inbox-header-resumes i {
    font-size: 16px;
    vertical-align: bottom;
    margin-right: 3px;
  }
  .inbox-header-resumes .attached-resume {
    vertical-align: middle !important;
  }
  .inbox-header-resumes-count {
    font-size: 11px;
    display: inline-block;
  }
  .inbox-header-resumes-count span {
    font-weight: 600;
  }

  .inbox-header-content {
    background: #ffffff;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    border-radius: 3px;
    padding: 10px 20px;
  }
  .demo-content {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    background: $accent-color-2;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 6px 8px;
    left: 0;
    z-index: 2;
  }
}

.inbox-messages-older {
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  transition: all 300ms linear;
  color: $secondary-text;
  cursor: pointer;
}
.inbox-messages-older p {
  margin-bottom: 0;
}
.inbox-messages-older .circle {
  vertical-align: top;
  margin-top: 1px;
  padding-top: 1px;
}
.inbox-messages-older:hover {
  background: $background-dark;
}
.inbox-messages {
  margin-bottom: 25px;
  overflow: auto;
}

.inbox-message {
  border-bottom: 1px solid $divider;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
  min-width: 45%;
  float: right;
  clear: both;
  border-radius: 3px;

  .message-sender {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px;
    color: $accent-color-3;
  }
  .message-sender.convo-participant {
    color: $accent-color;
  }

  .inbox-message-content {
    color: $secondary-text;
    white-space: pre-wrap;
  }
  .time-received {
    text-align: right;
    color: $secondary-text;
    font-size: 10px;
    font-style: italic;
  }
}

.inbox-new-messages-container {
  padding: 5px 5px 10px 5px;
  clear: both;
}
.inbox-new-messages {
  text-align: center;
  border-top: 1px solid $divider;
  margin-top: 15px;
  display: block;
}
.inbox-new-messages p {
  width: 180px;
  background: $background-medium;
  margin: -10px auto 15px auto;
  text-transform: uppercase;
  font-size: 12px;
}

.inbox-message.inbox-message-mine {
  float: left;
  background: $background-dark-medium;
}

.message-reply {
  margin-bottom: 25px;

  .message-reply-header {
    background: #ffffff;
    border-radius: 3px;
    border-bottom: 1px solid $divider;
    padding: 10px;
    font-weight: 600;
  }

  .message-reply-header-name {
    text-transform: capitalize;
  }
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: none;
    border-bottom: 1px solid $divider;
    resize: none;
    outline: none;
    font-size: 12px;
    &::-webkit-input-placeholder {
      color: $secondary-text;
    }
  }
}

.sidebar-content-section {
  background: $background-dark-medium;
  border-bottom: 1px solid $divider;
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 10px;

  h3 {
    color: $secondary-text;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
  }

  tr td:first-child {
    color: $secondary-text;
    font-size: 12px;
  }

  td {
    padding: 5px 0;
  }
}

.inbox-resume-header {
  margin: 20px 0 0 0;
  text-align: center;
  padding-top: 32px;
  border-bottom: none;

  .resume-header-image {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    margin: 0 auto;
    overflow: hidden;
  }
  .resume-header-image img {
    width: 100%;
    height: auto;
  }
  .resume-header-info {
    margin-bottom: 25px;
    width: 100%;

    .resume-name-container {
      padding-left: 0;
    }
    .resume-name {
      color: $primary-color;
      margin-bottom: 10px;
      margin-top: 12px;
    }
    .resume-fullName {
      display: block;
    }
    .resume-header-details p {
      display: inline-block;
      margin: 0 10px;
    }
    .resume-header-details .resume-header-detail-title {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      color: $secondary-text;
    }

    .resume-info-details {
      display: inline-block;
      color: $accent-color-3;
      background: $background-dark-medium;
      border-radius: 3px;
      margin-left: 10px;
      padding: 0 5px;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 17px;
      vertical-align: middle;
    }
  }
}

.inbox-container-max {
  max-width: 885px;
  margin: 0 auto;
}

.inbox-resume-section {
  text-align: left;
  background: #fff;
  border-radius: 3px;
  padding: 20px;
  border-bottom: 1px solid $divider;
  margin-top: 20px;
  cursor: default;

  .panelContainer {
    padding: 20px 0 0 0 !important;
    border-bottom: none;
    cursor: default;
  }

  .resume-column-left,
  .resume-column-right {
    width: 100%;
    margin-right: 0;
    float: none;
  }

  p {
    font-size: 13px !important;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
  }

  .resume-title-header {
    border-bottom: 3px solid grey;
    margin-bottom: 15px;
    padding-bottom: 0;
  }
  .resume-title-header h3 {
    margin-bottom: 0;
    font-size: 13px;
    color: $secondary-text;
  }

  .resume-section {
    padding: 0;
  }
  .resume-section p {
    line-height: 18px;
  }

  tbody tr {
    border: none;
    display: inline-block;
    margin-bottom: 8px;
  }
  td {
    padding: 0;
  }
  td:first-child {
    display: block;
    margin-bottom: 0;
  }
  td:last-child {
    font-size: 13px;
    word-break: break-all;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }
  .bold {
    font-weight: 600;
  }

  .language {
    font-size: 13px;
  }

  .reference-relationship {
    font-size: 12px;
    font-weight: normal;
    margin-left: 8px;
  }

  .resume-circle {
    display: none;
  }
  .resume-timeline {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}

/////////// ZIVUG ZONE /////////////
.inbox-chat-background {
  position: fixed;
  height: 100%;
  width: 100%;
  background: url(/images/inbox-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  //background-attachment: fixed;
}

.inbox-modal-actions {
  display: flex;
  justify-content: space-between;
  margin: 16px 16px 0 35px;
}

.inbox-modal-button {
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}
.inbox-modal-button-accept {
  background-color: $said-yes;
}

.inbox-modal-button-reject {
  background-color: red;
}
.inbox-modal-button-make-match {
  background-color: $accent-color;
}

.inbox-modal-resume-status {
  background-color: $waiting;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
