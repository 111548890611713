.admin-sidebar-left {
  position: absolute;
  left: 0;
  width: 350px;
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
}
.admin-content-main {
  position: absolute;
  left: 350px;
  right: 350px;
}
.admin-sidebar-content {
  position: absolute;
  right: 0;
  width: 350px;
}

.header-admin ul li {
}

@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .admin-content-main {
    right: 140px;
  }
}

@media only screen and (max-width: 720px) {
  .admin-sidebar-left {
    position: relative;
    left: auto;
    width: 100%;
    z-index: 5;
    height: auto;
    padding: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .admin-content-main {
    position: relative;
    left: auto;
    right: auto;
    background: $primary-color-dark;
  }

  #header {
    height: auto;
    //position: relative;
    .header-logo {
      width: 125px;
      padding-top: 0;
    }
    .header-menu-main li a {
      font-size: 12px;
      padding: 15px;
    }
  }

  #content-main-admin {
    position: relative;
    padding: inherit;
    padding-top: 93px;
  }
}
