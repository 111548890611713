@import "helpers/colors";
@import "helpers/reset";
@import "helpers/typography";

@import "admin/admin";
@import "admin/admin-header";

@import "components/search-filter";
@import "components/search-dropdown";
@import "components/modals";
@import "components/autosuggest";
@import "components/btns";
@import "components/loader";
@import "components/form";
@import "components/notifications";
@import "components/responses";
@import "components/toggle";
@import "components/popup";
@import "components/iframe";
@import "components/supportSidebar";
@import "components/table";
@import "components/pagination";
@import "components/tags";
@import "components/notifications-bar";
@import "components/loading-bar";
@import "components/browser-update";
@import "components/pendingShadchanMsg";

@import "layout/grid";
@import "layout/general";
@import "layout/header";
@import "layout/sub-header";

@import "layout/sidebar-left";
@import "layout/sidebar-right";

@import "pages/resume";
@import "pages/createResume";
@import "pages/inbox";
@import "pages/groups";
@import "pages/welcome";
@import "pages/ideas";
@import "pages/login";
@import "pages/register";
@import "pages/not-found";
@import "pages/terms";
@import "pages/create-resume-options";
@import "pages/shadchanim";
@import "pages/myMatches";
@import "pages/zzAdmin";

@import "../../node_modules/react-redux-toastr/src/styles/index";

body {
  font-family: "Open Sans", sans-serif;
  background: $background-medium;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.429;
  color: $primary-text;
}

body.mui-body--scroll-lock {
  overflow: auto !important;
}
