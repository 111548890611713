.autosuggest-column-small {
  width: 5%;
  margin-right: 2%;
  display: inline-block;
}
.autosuggest-column-medium {
  width: 20%;
  display: inline-block;
}
.autosuggest-column-large {
  width: 53%;
  display: inline-block;
}
.autosuggest-column-extraLarge {
  width: calc(53% + 40px + 5%);
  display: inline-block;
}

.autosuggest .form-section {
  background-color: #fff;
  border-radius: 3px;
  padding: 20px 20px 0 20px;
}

.autosuggest-list {
  margin-bottom: 20px;
  .react-autosuggest__suggestions-container {
    position: relative;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    position: absolute;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border: 1px solid $background-medium;
    border-radius: 3px;
    z-index: 100;
    background: $background-medium;
  }

  .autosuggest-list-item {
    border-bottom: 1px solid $divider;
    padding: 20px;
    transition: all 0.2s linear;
    background: $background-medium;
    position: relative;

    &:hover {
      background: $active-color;
      cursor: pointer;
    }

    .resume-status {
      position: absolute;
      right: 10px;
      top: 0;
    }

    .autosuggest-list-item-unread,
    .autosuggest-list-item-read {
      font-size: 10px;
      vertical-align: top;
      line-height: 40px;
      color: $accent-color;
    }
    .autosuggest-list-item-read {
      text-indent: -99999px;
    }
    .autosuggest-list-item-image {
      width: 40px;
      height: 40px;
      border-radius: 3px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5%;

      span {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: $background-dark;
        display: inline-block;
        text-align: center;
        border-radius: 3px;
        text-transform: uppercase;
      }
    }
    .autosuggest-list-item-info {
      .autosuggest-list-item-title {
        margin-top: 3px;
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
      }
      .autosuggest-list-item-unread-title {
        font-weight: 600;
      }
      .autosuggest-list-item-info-details,
      .autosuggest-list-item-info-types {
        margin-bottom: 0;
        line-height: 16px;
      }
      .autosuggest-list-item-info-details span {
        margin-right: 10px;
        font-size: 12px;
        font-family: "Open Sans";
        color: $accent-color-3;
      }
      .autosuggest-list-item-info-types span {
        font-size: 12px;
        font-family: "Open Sans";
        color: $accent-color-3;
      }
    }
    .autosuggest-list-item-extra {
      vertical-align: top;
      color: $accent-color-2;
      text-align: right;
      font-size: 12px;
      text-transform: lowercase;
    }
    .autosuggest-list-item-extra .border {
      color: $primary-color;
      font-size: 11px;
      display: inline-block;
    }
  }
  .autosuggest-list-item-active {
    background: $active-color;
    border-right: 2px solid $primary-color;
  }
}

.resume-suggestions-list {
  .react-autosuggest__suggestions-list {
    padding-left: 0;
  }
}

.container.autosuggest-list {
  max-width: 100%;
}

.autosuggest-dropdown {
  margin-bottom: 16px;
  .rw-filter-input {
    padding-right: 0;
  }
  .rw-filter-input .rw-select.rw-btn {
    padding-top: 4px;
  }
  .rw-list {
    background-color: $background-medium;
    max-height: 240px;
  }
  .rw-list > li {
    overflow: auto;
    clear: both;
    padding: 8px 0 8px 8px;
    border-bottom: 1px solid $divider !important;
    position: relative;
  }
  .rw-list > li:hover {
    border: 1px solid transparent !important;
    background: $background-dark-medium !important;
  }
  .autosuggest-list-item-image {
    height: 40px;
    width: 40px;
    margin-top: 3px;
    float: left;
    border-radius: 3px;
    margin-right: 16px;
    vertical-align: top;
  }
  .autosuggest-list-item-info {
    float: left;
    padding-top: 6px;
  }
  .autosuggest-list-item-info h5 {
    margin-bottom: 0;
  }
  .autosuggest-list-item-info ul {
    padding-left: 0;
    list-style-type: none;
    font-size: 12px;
    color: #727272;
    width: 302px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    li {
      border-bottom: none;
      display: inline-block;
    }
    li:after {
      content: "•";
      display: inline-block;
      padding-left: 4px;
      padding-right: 4px;
    }
    li:first-of-type {
      margin-right: 3px;
    }
    li:last-of-type:after {
      content: "";
    }
  }
  .autosuggest-list-item-info-details span {
    margin-right: 8px;
  }
  .resume-status {
    position: absolute;
    right: 25px;
    width: 64px;
    top: 12px;
    .fa-check {
      font-size: 12px;
      color: $primary-color;
      margin-right: 8px;
    }
    .is-member {
      font-size: 11px;
      color: $primary-color;
    }
    .has-resume {
      @extend .is-member !optional;
      margin-top: 16px;
    }
  }
}

@media only screen and (max-width: 544px) {
  .autosuggest-list-item-image {
    display: none;
  }
  .resume-suggestions-list ul.react-autosuggest__suggestions-list > li {
    min-height: 96px !important;
  }
  .resume-suggestions-list .resume-status:last-of-type {
    bottom: 0px;
  }
  .resume-suggestions-list .resume-status {
    bottom: 12px;
    right: 8px !important;
  }
}
